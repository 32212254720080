import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInput, required, maxLength, renderErrors } from '../../../hooks/input'
import rolesService from '../../../services/roles'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'


const Create = () => {
  const name = useInput('text', '', [required(), maxLength(32)])
  const description = useInput('textarea')
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const formHasErrors = [
    name.hasErrors(),
    description.hasErrors(),
  ].some((x) => x)


  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    rolesService
      .post(
        {
          name: name.value,
          description: description.value,
        }
      )
      .then(() => {
        dispatch(showAlert(__('Role created'), 'success'))
        history.push({ pathname: '/admin/authorization', hash: '#roles' })
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }

  return (
    <>
      <h3>{__('Create role')}</h3>

      <hr />

      <form onSubmit={handleOnSubmit}>
        <div className="form-group">
          <label htmlFor="name">{__('Role')}</label>
          <input className="form-control" name="name" {...name.inputProps} />
          {renderErrors(name, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="description">{__('Description')}</label>
          <textarea className="form-control" name="description" rows="5" onChange={description.onChange} value={description.value} />
        </div>

        <Link to={{ pathname: '/admin/authorization', hash: '#roles' }} className="btn btn-secondary mr-1">
          {__('Back')}
        </Link>
        <button type="submit" className="btn btn-primary" disabled={isFormSubmitted && formHasErrors}>
          {__('Save role')}
        </button>
      </form>
    </>
  )
}

export default Create
