import React from 'react'
import CallsOutDateGrouppedDataTable from './CallsOutDateGrouppedDataTable'
import StatisticCard from '../StatisticCard'
import { CallCountsBarChart, CallMinutesLineChart } from '../Charts'
import { useCallsOutGroupByDateReport } from '../../../hooks/calls'
import { __ } from '../../../utils/translate'


const CallsOutDateGrouppedReport = ({ start, end }) => {
  const [report] = useCallsOutGroupByDateReport(start, end)

  return (report !== null) ? (
    <>
      <div className="row">
        <div className="col-xl-6">
          <div className="row d-print-none">
            <div className="col">
              <span className="text-muted">{__('Minutes')}</span>
              <CallMinutesLineChart data={report.calls} showDateLabels={false} />
            </div>
          </div>
          <div className="row mt-3 mb-1">
            <div className="col">
              <StatisticCard icon="clock" text={__('Total call duration')} value={`${report.totals.minutesHuman} min`} />
            </div>
          </div>
        </div>
        <div className="col-xl-6">
          <div className="row d-print-none">
            <div className="col">
              <span className="text-muted">{__('Calls')}</span>
              <CallCountsBarChart data={report.calls} showDateLabels={false} />
            </div>
          </div>
          <div className="row mt-3 mb-1">
            <div className="col">
              <StatisticCard icon="phone-square" text={__('Total calls')} value={report.totals.calls} />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <CallsOutDateGrouppedDataTable calls={report.calls} />
        </div>
      </div>
    </>
  ) : <></>
}

export default CallsOutDateGrouppedReport
