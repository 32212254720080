import { useState, useEffect } from 'react'
import applicationNumbersService from '../services/application-numbers'

export const useNumbers = (customerId, applicationId) => {
  const [numbers, setNumbers] = useState([])

  useEffect(() => {
    applicationNumbersService
      .getAll(customerId, applicationId)
      .then((nums) => setNumbers(nums))
  }, [customerId, applicationId])

  return [numbers, setNumbers]
}

export const useNumber = (customerId, applicationId, cNumber) => {
  const [number, setNumber] = useState(null)

  useEffect(() => {
    applicationNumbersService
      .get(customerId, applicationId, cNumber)
      .then((num) => setNumber(num))
  }, [customerId, applicationId, cNumber])

  return [number, setNumber]
}

export const useAllApplicationNumbers = (customerId, page, limit) => {
  const [numbers, setNumbers] = useState([])

  useEffect(() => {
    applicationNumbersService
      .getAllApplicationNumbers(customerId, page, limit)
      .then((nums) => setNumbers(nums.data))
  }, [customerId, page, limit])

  return [numbers, setNumbers]
}
