import React, { useState } from 'react'
import CallsDateRangePicker from '../CallsDateRangePicker'
import { usePhoneNumbers } from '../../../hooks/phone-numbers'
import { __ } from '../../../utils/translate'
import { lastMonth } from '../../../utils/date'


const CallsInReportForm = ({ onSubmit }) => {
  const [first, last] = lastMonth()
  const [start, setStart] = useState(first)
  const [end, setEnd] = useState(last)
  // This doesn't work if user has more than 1M numbers...
  const [phoneNumbers] = usePhoneNumbers(1, 1000000)
  const [selectedPhone, setSelectedPhone] = useState('')
  const [groupBy, setGroupBy] = useState('date')

  const handleDateRangeChange = (data) => {
    setStart(data.selection.startDate)
    setEnd(data.selection.endDate)
  }

  const handlePhoneNumberChange = (e) => {
    const val = e.target.value

    if (val !== '') {
      setGroupBy('date')
    } else {
      setGroupBy('')
    }

    setSelectedPhone(val)
  }

  const handleGroupByChange = (e) => {
    const val = e.target.value

    if (val === 'phone') {
      setSelectedPhone('')
    }

    setGroupBy(val)
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    onSubmit(selectedPhone, start, end, groupBy)
  }

  const phoneSelectDisabled = groupBy === 'phone'
  const groupBySelectDisabled = selectedPhone !== ''

  return (
    <form className="form-inline" onSubmit={handleOnSubmit}>
      <div className="input-group mb-2 mr-sm-2">
        <label className="sr-only" htmlFor="number">
          {__('Phone number')}
        </label>
        <select className="custom-select" value={selectedPhone} onChange={handlePhoneNumberChange} disabled={phoneSelectDisabled}>
          <option value=""> ** {__('For all phone numbers')} ** </option>
          {phoneNumbers.map((pn) => (
            <option key={pn.cNumber} value={pn.cNumber}>{pn.phoneNumber}</option>
          ))}
        </select>
      </div>

      <label className="sr-only" htmlFor="end">
        {__('Period')}
      </label>
      <CallsDateRangePicker start={start} end={end} onDateRangeChange={handleDateRangeChange} />

      <div className="input-group mb-2 mr-sm-2">
        <div className="form-check">
          <label className="sr-only" htmlFor="number">
            {__('Group by')}
          </label>
          <select className="custom-select" value={groupBy} onChange={handleGroupByChange} disabled={groupBySelectDisabled}>
            <option value=""> ** {__('No groupping')} ** </option>
            <option value="date">{__('Group by date')}</option>
            <option value="phone">{__('Group by phone number')}</option>
          </select>
        </div>
      </div>

      <button type="submit" className="btn btn-primary mb-2 d-print-none">
        {__('Show report')}
      </button>
    </form>
  )
}

export default CallsInReportForm
