import React from 'react'
import { useParams } from 'react-router-dom'
import Tabs from '../../Tabs'
import CreateRangeForm from './CreateRangeForm'
import CreateListForm from './CreateListForm'
import { __ } from '../../../utils/translate'

const CreateRange = () => {
  const customerId = useParams().custid
  const applicationId = useParams().appid

  const ids = { customerId, applicationId }

  return (
    <>
      <h3>{__('Create connected number range')}</h3>
      <hr />
      <Tabs>
        <div label="range" title={__('Number range')}>
          <CreateRangeForm {...ids} />
        </div>
        <div label="list" title={__('Number list')}>
          <CreateListForm {...ids} />
        </div>
      </Tabs>
    </>
  )
}

export default CreateRange
