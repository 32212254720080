import React from 'react'
import './ClickableParagraph.scss'

const ClickableParagraph = ({ onClick, icon, text, children }) => {
  const content = (icon && text)
    ? <><i className={`fa fa-${icon} mr-1`} />{text}</>
    : children

  return (
    <div className="btn-link-plain">
      <button type="button" onClick={onClick} className="btn btn-link">
        {content}
      </button>
    </div>
  )
}

export default ClickableParagraph
