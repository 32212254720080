export const bodyClassToggle = (className) => {
  document.body.classList.toggle(className)
}

export const bodyClassAdd = (className) => {
  document.body.classList.add(className)
}

export const bodyClassRemove = (className) => {
  document.body.classList.remove(className)
}
