import axios from 'axios'
import { responseMap } from '../utils/axios'

const geAllForPeriod = (start, end) =>
  axios
    .get(`/api/employees/shifts?start=${start}&end=${end}`)
    .then(responseMap)

const get = (employeeId, date) =>
  axios
    .get(`/api/employees/shifts/${employeeId}/${date}`)
    .then(responseMap)

const put = (employeeId, date, hours) =>
  axios
    .put(`/api/employees/shifts/${employeeId}/${date}`, { hours })
    .then(responseMap)

export default { geAllForPeriod, get, put }
