import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Modal from './Modal'
import loginService from '../services/login'
import { userLogout } from '../reducers/authentication'
import { __ } from '../utils/translate'

const Logout = ({ className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleLogout = () =>
    loginService
      .doLogout()
      .then(() => {
        dispatch(userLogout())
        history.push('/')
      })

  return (
    <>
      <button type="button" className={className} onClick={() => setIsModalOpen(true)}>
        {__('Logout')}
      </button>
      <Modal
        isOpen={isModalOpen}
        title={__('Leave site?')}
        onConfirm={handleLogout}
        onClose={() => setIsModalOpen(false)}
        confirmText={__('Logout')}
        closeText={__('Cancel')}
      >
        <p>{__('Select Logout below if you are ready to end your current session')}</p>
      </Modal>
    </>
  )
}

export default Logout
