import React, { useEffect, useState } from 'react'
import { isSameDay, isBefore } from 'date-fns'
import Hour from './Hour'
import shiftsService from '../../services/employees-shifts'
import { __ } from '../../utils/translate'
import { dateToDateStr } from '../../utils/date'


const EditDay = ({ day, saveDisabled, employee, onSave }) => {
  const [selected, setSelected] = useState([])

  const shiftsToHours = (shifts) => shifts.map((s) => s.hour)
  const hours = Array.from(Array(24).keys())

  const now = new Date()
  const isDayInPast = isBefore(day, now) && !isSameDay(day, now)
  const isHourDisabled = (h) => {
    if (isDayInPast) {
      return true
    }

    return (isSameDay(day, now)) && h < now.getHours()
  }

  useEffect(() => {
    shiftsService
      .get(
        employee.employeeId,
        dateToDateStr(day)
      )
      .then((shifts) => {
        setSelected(shiftsToHours(shifts))
      })
  }, [day, employee])


  const handleOnChange = (event) => {
    const add = !!event.target.checked
    const hour = +event.target.value

    const newState = (add)
      ? selected.concat(hour)
      : selected.filter((h) => h !== hour)

    setSelected(newState)
  }

  const handleSave = () => {
    shiftsService
      .put(
        employee.employeeId,
        dateToDateStr(day),
        selected.filter((h) => !isHourDisabled(h))
      )
      .then(() => onSave())
      .catch((error) => window.alert(error.response.data.errors.join(', ')))
  }


  return (
    <div className="container text-center">
      <div className="row">
        <div className="col-md-6">
          {hours.slice(0, 12).map((h) => (
            <Hour
              key={h}
              hour={h}
              checked={selected.includes(h)}
              onChange={handleOnChange}
              isDisabled={isHourDisabled(h)}
            />
          ))}
        </div>
        <div className="col-md-6">
          {hours.slice(12).map((h) => (
            <Hour
              key={h}
              hour={h}
              checked={selected.includes(h)}
              onChange={handleOnChange}
              isDisabled={isHourDisabled(h)}
            />
          ))}
        </div>
      </div>
      <div className="mt-4 mb-3 text-right">
        {!saveDisabled && (
          <button type="button" className="btn btn-primary btn-lg" onClick={handleSave} disabled={saveDisabled}>
            {__('Save')}
          </button>
        )}
      </div>
    </div>
  )
}

export default EditDay
