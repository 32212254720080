import React from 'react'
import './Hour.scss'

const Hour = ({ hour, checked, onChange, isDisabled }) => {
  const hourToStr = (int) => {
    const h = int.toString().padStart(2, '0')
    return `${h}:00 - ${h}:59`
  }

  return (
    <div className="form-check hour-container mb-2">
      <input
        type="checkbox"
        className="form-check-input hour-lg-checkbox"
        name={`hour-${hour}`}
        value={hour}
        checked={checked}
        onChange={onChange}
        disabled={isDisabled}
      />
      <label className="form-check-label ml-2" htmlFor={`hour-${hour}`}>
        {hourToStr(hour)}
      </label>
    </div>
  )

  /*
  return (
    <div className="custom-control form-control-lg custom-checkbox">
      <input
        type="checkbox"
        className="custom-control-input"
        value={hour}
        checked={checked}
        onChange={onChange}
      />
      <label className="custom-control-label check-label-lg">
        {hourToStr(hour)}
      </label>
    </div>
  )
  */
}

export default Hour
