import React, { useState } from 'react'
import { __ } from '../../../utils/translate'
import ClickableParagraph from '../../ClickableParagraph'

const EditRoles = ({ roles, selected, onChange }) => {
  const [showEdit, setShowEdit] = useState(true)

  const toggleShowEdit = () => setShowEdit(!showEdit)

  if (showEdit) {
    return (
      <>
        <div className="form-group mb-3">
          <ClickableParagraph onClick={toggleShowEdit} icon="caret-up" text={__('Roles')} />
          {roles.map((role) => (
            <div key={role.roleId} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value={role.roleId}
                checked={selected.includes(role.roleId)}
                onChange={onChange}
              />
              <label className="form-check-label" htmlFor="role">{role.name}</label>
            </div>
          ))}
        </div>
      </>
    )
  }

  const selectedRoleNames = selected.map((id) => {
    const role = roles.find((r) => r.roleId === id)
    return (role) ? role.name : 'error'
  })

  return (
    <ClickableParagraph onClick={toggleShowEdit}>
      <i className="fa fa-caret-down mr-1" />
      <strong className="mr-1">{__('Roles')}:</strong>
      {selectedRoleNames.join(', ')}
    </ClickableParagraph>
  )
}

export default EditRoles
