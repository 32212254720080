import React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import loginService from '../../services/login'
import { userLogout } from '../../reducers/authentication'
import { clearGenericError } from '../../reducers/ui'
import { __ } from '../../utils/translate'

const UnknownError = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleReturnToHomeClick = () => {
    dispatch(clearGenericError())
    history.push('/')
  }

  const handleLogout = () =>
    loginService
      .doLogout()
      .then(() => {
        dispatch(userLogout())
        handleReturnToHomeClick()
      })

  return (
    <div className="container">
      <div className="card mx-auto mt-5">
        <div className="card-header">
          {__('Something went wrong')}
        </div>
        <div className="card-body">
          {__('This page did not load correctly. Try again later')}
          <hr />
          <p className="text-right">
            <button type="button" className="btn btn-secondary mr-2" onClick={handleLogout}>
              {__('Logout')}
            </button>
            <button type="button" className="btn btn-secondary" onClick={handleReturnToHomeClick}>
              {__('Return to homepage')}
            </button>
          </p>
        </div>
      </div>
    </div>
  )
}

export default UnknownError
