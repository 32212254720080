import React from 'react'
import { Link } from 'react-router-dom'
import EmployeeIcon from '../shifts/EmployeeIcon'
import { __ } from '../../utils/translate'
import { userHasPermission } from '../../utils/authorization'

const TableRow = ({ employee, onDeleteClick }) => {
  const em = employee
  return (
    <>
      <tr>
        <td>{em.phoneNumber}</td>
        <td>
          <EmployeeIcon employee={em} />
          &nbsp;{em.identifier}
        </td>
        <td>{em.user.email}</td>
        <td>
          {userHasPermission('update-employees') && (
            <Link className="btn btn-light btn-sm mr-1" role="button" to={`/employees/${employee.employeeId}/edit`} title={__('edit')}>
              <i className="fas fa-pen" />
            </Link>
          )}
          {userHasPermission('delete-employees') && (
            <button type="button" className="btn btn-light btn-sm" onClick={() => onDeleteClick(em.employeeId)} title={__('delete')}>
              <i className="fas fa-trash" />
            </button>
          )}
        </td>
      </tr>
    </>
  )
}

export default TableRow
