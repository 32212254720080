import React from 'react'
import { isSameDay, isBefore } from 'date-fns'
import HoursForm from './HoursForm'
import SelectEmployee from './SelectEmployee'
import EmployeeIcon from './EmployeeIcon'
import { __ } from '../../utils/translate'
import { dateToFiDateStr } from '../../utils/date'
import { userHasPermission } from '../../utils/authorization'
import DaySummary from './DaySummary'

/**
 * Edit view for customer user.
 *
 * Customer can edit shifts for all customer's employees.
 * There is some duplicate code with employee user's EditShifts
 * component. Should refactor these...
 */
const EditDay = ({ day, selectedEmployee, employees, onDone, onEmployeeChange }) => {
  const today = new Date()
  const isDayInPast = isBefore(day, today) && !isSameDay(day, today)

  const headerMargin = (isDayInPast) ? 3 : 5

  return (
    <div className="container">
      <div className="row">

        <div className="col-md-6">
          <h3 className={`mb-${headerMargin}`}>
            {__('Shifts')}
            <strong className="ml-3">{dateToFiDateStr(day)}</strong>
          </h3>
          {isDayInPast && (
            <p>
              <small className="text-danger">{__('Past shifts can\'t be modified')}</small>
            </p>
          )}

          <h5>
            <span className="mr-2">{__('Employee')}</span>
            {selectedEmployee && <EmployeeIcon employee={selectedEmployee} />}
          </h5>
          <SelectEmployee
            employees={employees}
            selectedEmployee={selectedEmployee}
            onEmployeeChange={onEmployeeChange}
          />

          <hr />

          <button type="button" className="btn btn-secondary btn-sm" onClick={onDone}>
            {__('Back')}
          </button>
        </div>

        <div className="col-md-6">
          {selectedEmployee && (
            <HoursForm
              day={day}
              employee={selectedEmployee}
              onSave={onDone}
              saveDisabled={isDayInPast || !userHasPermission('update-employees-shifts')}
            />
          )}
        </div>

      </div>

      <div className="card mt-4">
        <div className="card-header">
          <i className="fas fa-calendar mr-1" /> {dateToFiDateStr(day)}
        </div>
        <div className="card-body">
          <DaySummary day={day} excludeEmployee={selectedEmployee} />
        </div>
      </div>

    </div>
  )
}

export default EditDay
