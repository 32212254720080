import React, { useCallback, useEffect, useState } from 'react'
import { useTable, usePagination } from 'react-table'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { __ } from '../../utils/translate'
import { userHasPermissions } from '../../utils/authorization'
import PaginationNav from '../PaginationNav'
import phoneNumbersService from '../../services/phone-numbers'

const ActionsCell = ({ row }) => (
  <>
    {userHasPermissions(['update-phone-numbers', 'show-media']) && (
      <Link className="btn btn-light btn-sm" role="button" to={`/phone-numbers/${row.original.cNumber}/edit`} title={__('edit')}>
        <i className="fas fa-pen" />
      </Link>
    )}
  </>
)

const ReactTable = ({ columns, data, perPage, maxPages, fetchData }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      autoResetPage: false,
      pageCount: maxPages,
      initialState: {
        pageIndex: 0,
        pageSize: perPage,
      },
    },
    usePagination
  )

  useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize })
    }
  }, [fetchData, pageIndex, pageSize])


  return (
    <>
      <div className="table-responsive">
        <table className="table table-bordered" width="100%" cellSpacing="0" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <PaginationNav
        pageIndex={pageIndex}
        maxPages={maxPages}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </>
  )
}

const Table = () => {
  const perPage = 50
  const [maxPages, setMaxPages] = useState(-1)
  const [data, setData] = useState([])

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      phoneNumbersService
        .getAll(pageIndex + 1, pageSize)
        .then((resp) => {
          setMaxPages(Math.ceil(resp.total / resp.limit))
          setData(resp.data)
        })
    },
    []
  )

  const columns = [
    {
      Header: __('Phone number'),
      accessor: 'phoneNumber',
    },
    {
      Header: __('Application'),
      accessor: 'application.name',
    },
    {
      Header: __('Media'),
      accessor: 'media',
    },
    {
      Header: __('Description'),
      accessor: (row) => row.description.slice(0, 100),
    },
    {
      Header: '',
      id: 'actions',
      Cell: ActionsCell,
    },
  ]

  return (
    <ReactTable
      data={data}
      columns={columns}
      perPage={perPage}
      maxPages={maxPages}
      fetchData={fetchData}
    />
  )
}

export default Table
