import store from './store'

const getUser = () => {
  const state = store.getState()
  const user = state.authentication

  if (!user) {
    throw new Error('Could not access user')
  }

  return user
}

export const userHasPermissions = ([...permissions]) =>
  permissions.every((name) =>
    getUser().permissions.includes(name))

export const userHasPermission = (permission) => userHasPermissions([permission])

export const userHasSomePermissions = ([...permissions]) =>
  permissions.some((name) => userHasPermission(name))

export const shiftsAreAvailable = () => !!getUser().customer.fid
