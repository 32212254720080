import React from 'react'
import { Line, Bar } from 'react-chartjs-2'
import { __ } from '../../utils/translate'

/**
 * Both line and bar chart expect data to be:
 * [
 *  {date: 'yyyy-mm-dd', calls: 12, minutes: 12.71, },
 *  {date: 'yyyy-mm-dd', calls: 8, minutes: 8.77, },
 *   ...
 * ]
 *
 * or at least each row should have properties
 * date, calls and minutes.
 */

export const CallMinutesLineChart = ({ data, showDateLabels = true }) => {
  const chartData = {
    labels: data.map((row) => row.date),
    datasets: [{
      label: __('minutes'),
      lineTension: 0.3,
      backgroundColor: 'rgba(2,117,216,0.2)',
      borderColor: 'rgba(2,117,216,1)',
      pointRadius: 5,
      pointBackgroundColor: 'rgba(2,117,216,1)',
      pointBorderColor: 'rgba(255,255,255,0.8)',
      pointHoverRadius: 5,
      pointHoverBackgroundColor: 'rgba(2,117,216,1)',
      pointHitRadius: 50,
      pointBorderWidth: 2,
      data: data.map((row) => row.minutes),
    }],
  }

  const options = {
    scales: {
      xAxes: [{
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 7,
          display: showDateLabels,
        },
      }],
      yAxes: [{
        ticks: {
          min: 0,
          // max: 40000,
          // maxTicksLimit: 5
        },
        gridLines: {
          color: 'rgba(0, 0, 0, .125)',
        },
      }],
    },
    legend: {
      display: false,
    },
  }

  return <Line data={chartData} height={120} options={options} />
}


export const CallCountsBarChart = ({ data, showDateLabels = true }) => {
  const chartData = {
    labels: data.map((row) => row.date),
    datasets: [{
      label: __('calls'),
      backgroundColor: 'rgba(204,213,2,0.4)',
      borderColor: 'rgba(204,213,2,1)',
      borderWidth: 3,
      data: data.map((row) => row.calls),
    }],
  }

  const options = {
    scales: {
      xAxes: [{
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 7,
          display: showDateLabels,
        },
      }],
      yAxes: [{
        ticks: {
          min: 0,
        },
        gridLines: {
          color: 'rgba(0, 0, 0, .125)',
        },
      }],
    },
    legend: {
      display: false,
    },
  }

  return <Bar data={chartData} height={120} options={options} />
}
