import React, { useState, useEffect } from 'react'
import CreateForm from './CreateForm'
import permissionsService from '../../../services/permissions'
import { __ } from '../../../utils/translate'


const useMissingPermissions = () => {
  const [permissions, setPermissions] = useState(null)

  useEffect(() => {
    permissionsService
      .getMissing()
      .then((perms) => setPermissions(perms))
  }, [])

  return [permissions, setPermissions]
}


const Create = () => {
  const [permissions] = useMissingPermissions()

  if (permissions === null) {
    return <></>
  }

  return (
    <>
      <h3>{__('Add missing permissions')}</h3>
      <hr />
      <CreateForm permissions={permissions} />
    </>
  )
}

export default Create
