import { useEffect, useState } from 'react'
import callsService from '../services/calls'


export const useCallsSummary = (start, end) => {
  const [report, setReport] = useState(null)

  useEffect(() => {
    const fetch = async () =>
      callsService
        .getCallsSummary(start, end)
        .then(setReport)
    fetch()
  }, [start, end])

  return [report, setReport]
}


export const useCallsInReport = (phoneNumber, start, end) => {
  const [report, setReport] = useState(null)

  useEffect(() => {
    callsService
      .getInCalls(phoneNumber, start, end)
      .then(setReport)
  }, [phoneNumber, start, end])

  return [report, setReport]
}


export const useCallsInGroupByDateReport = (phoneNumber, start, end) => {
  const [report, setReport] = useState(null)

  useEffect(() => {
    callsService
      .getInCallsGroupByDate(phoneNumber, start, end)
      .then(setReport)
  }, [phoneNumber, start, end])

  return [report, setReport]
}


export const useCallsInGroupByPhoneNumberReport = (start, end) => {
  const [report, setReport] = useState(null)

  useEffect(() => {
    callsService
      .getInCallsGroupByPhoneNumber(start, end)
      .then(setReport)
  }, [start, end])

  return [report, setReport]
}

export const useCallsOutGroupByDateReport = (start, end) => {
  const [report, setReport] = useState(null)

  useEffect(() => {
    callsService
      .getOutCallsGroupByDate(start, end)
      .then((rep) => setReport(rep))
  }, [start, end])

  return [report, setReport]
}


export const useCallsOutGroupByEmployeeReport = (start, end) => {
  const [report, setReport] = useState(null)

  useEffect(() => {
    callsService
      .getOutCallsGroupByEmployee(start, end)
      .then((rep) => setReport(rep))
  }, [start, end])

  return [report, setReport]
}
