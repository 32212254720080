const LOG_LEVELS = {
  DEBUG: 100,
  INFO: 200,
  NOTICE: 250,
  WARNING: 300,
  ERROR: 400,
  CRITICAL: 500,
  ALERT: 550,
  EMERGENCY: 600,
}

// TODO: add this to .env
//  something like logLevelStrToInt(process.env.LOG_LEVEL || 'ERROR')
const LOG_LEVEL = 400

const logLevelStrToInt = (level) => {
  const lvl = level.toUpperCase()

  if (lvl in LOG_LEVELS) {
    return LOG_LEVELS[lvl]
  }

  // default to off
  return 999
}

const log = (level, message, context = null) => {
  const lvl = level.toUpperCase()

  if (logLevelStrToInt(lvl) < LOG_LEVEL) {
    return
  }

  const dt = (new Date()).toISOString()
  const cntx = (context !== null) ? JSON.stringify(context) : '[]'

  const msg = `[${dt}] ${lvl}: ${message}`
  const msgAndContext = `${msg} ${cntx}`
  // Send msgAndContext to server...

  // For now, just log to console
  console.log(msgAndContext) // eslint-disable-line no-console
  // Dump context also for inspecting
  if (context) {
    console.log(context) // eslint-disable-line no-console
  }
}

export const logDebug = (message, context) => log('debug', message, context)
export const logInfo = (message, context) => log('info', message, context)
export const logWarning = (message, context) => log('warning', message, context)
export const logError = (message, context) => log('error', message, context)
export const logCritical = (message, context) => log('critical', message, context)
export const logAlert = (message, context) => log('alert', message, context)
export const logEmergency = (message, context) => log('emergency', message, context)

export default {
  debug: logDebug,
  info: logInfo,
  warning: logWarning,
  error: logError,
  critical: logCritical,
  alert: logAlert,
  emergency: logEmergency,
}
