import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import axios from 'axios'
import App from './App'
import store from './utils/store'
import './index.scss'
import translationsService from './services/translations'
import { setTranslations } from './utils/translate'
import { interceptors } from './utils/axios'


// Add a request interceptor
axios.interceptors.request.use(interceptors.beforeRequest, interceptors.onRequestError)
// Add a response interceptor
axios.interceptors.response.use(interceptors.afterSuccessfulResponse, interceptors.onResponseError)


// Load translations before rendering anything :(
const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )

  return null
}

const lang = (new URLSearchParams(window.location.search)).get('lang')

translationsService
  .getAll(lang)
  .then((data) => {
    setTranslations(data)
    renderApp()
  })
  .catch(() => {
    ReactDOM.render(
      <React.StrictMode>
        <pre>Failed to load translations.</pre>
      </React.StrictMode>,
      document.getElementById('root')
    )
    return null
  })
