import React from 'react'
import { __ } from '../utils/translate'

const PaginationNav = ({
  pageIndex = 0,
  maxPages = 1,
  gotoPage = (_) => null,
  canPreviousPage = false,
  canNextPage = false,
  previousPage = () => null,
  nextPage = () => null,
}) => {
  const handlePageInputChange = (e) => {
    const n = (e.target.value) ? Number(e.target.value) - 1 : 0
    gotoPage(n)
  }

  return (
    <div className="row">
      <div className="col-4">
        {__('Page')} {pageIndex + 1}/{maxPages}
      </div>
      <div className="col-4">
        {__('Goto page')} <input className="" type="number" onChange={handlePageInputChange} defaultValue={pageIndex + 1} style={{ width: '15%' }} />
      </div>
      <div className="col-4">
        <nav>
          <ul className="pagination justify-content-end">
            <li className={`page-item ${pageIndex === 0 && 'disabled'}`}>
              <button type="button" className="page-link" onClick={() => gotoPage(0)}>{__('First')}</button>
            </li>
            <li className={`page-item ${canPreviousPage || 'disabled'}`}>
              <button type="button" className="page-link" onClick={() => previousPage()}>{__('Previous')}</button>
            </li>
            <li className={`page-item ${canNextPage || 'disabled'}`}>
              <button type="button" className="page-link" onClick={() => nextPage()}>{__('Next')}</button>
            </li>
            <li className={`page-item ${pageIndex === maxPages - 1 && 'disabled'}`}>
              <button type="button" className="page-link" onClick={() => gotoPage(maxPages - 1)}>{__('Last')}</button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}

export default PaginationNav
