import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { __ } from '../utils/translate'
import { userHasPermissions, shiftsAreAvailable, userHasSomePermissions } from '../utils/authorization'
import { bodyClassAdd, bodyClassRemove } from '../utils/dom'
import './LeftSidebar.scss'

const LeftSidebar = () => {
  const [isReportExpanded, setIsReportExpanded] = useState(false)
  const isLeftSidebarCollapsed = useSelector((state) => state.ui.isLeftSidebarCollapsed)

  useEffect(() => {
    const f = (isLeftSidebarCollapsed) ? bodyClassAdd : bodyClassRemove
    f('sidebar-toggled')
  }, [isLeftSidebarCollapsed])

  const toggleReports = () => setIsReportExpanded(!isReportExpanded)

  const ulClassNames = ['sidebar', 'navbar-nav', 'd-print-none']
  if (isLeftSidebarCollapsed) {
    ulClassNames.push('toggled')
  }

  return (
    <ul className={ulClassNames.join(' ')}>
      <li className="nav-item">
        <Link className="nav-link" to="/">
          <i className="fas fa-fw fa-tachometer-alt" />
          <span>{__('Dashboard')}</span>
        </Link>
      </li>
      {userHasSomePermissions(['show-calls-in-report', 'show-calls-out-report', 'show-calls-media-report']) && (
        <li className={`nav-item dropdown ${isReportExpanded ? 'show' : ''}`}>
          <button type="button" className="btn btn-link nav-link dropdown-toggle" onClick={toggleReports} id="reports-dropdown" aria-haspopup="true" aria-expanded={isReportExpanded}>
            <i className="fas fa-fw fa-table" />
            <span>{__('Call reports')}</span>
          </button>
          {isReportExpanded && (
            <div className="dropdown-menu show" aria-labelledby="reports-dropdown">
              {userHasPermissions(['show-calls-in-report', 'show-phone-numbers']) && (
                <Link className="dropdown-item" to="/calls/in">
                  {__('Calls')}
                </Link>
              )}
              {userHasPermissions(['show-calls-out-report']) && (
                <Link className="dropdown-item" to="/calls/out">
                  {__('Employee calls')}
                </Link>
              )}
              {userHasPermissions(['show-calls-media-report']) && (
                <Link className="dropdown-item" to="/calls/media">
                  {__('Media report')}
                </Link>
              )}
            </div>
          )}
        </li>
      )}
      {userHasPermissions(['show-phone-numbers']) && (
        <li className="nav-item">
          <Link className="nav-link" to="/phone-numbers">
            <i className="fas fa-fw fa-phone" />
            <span>{__('Phone numbers')}</span>
          </Link>
        </li>
      )}
      {userHasPermissions(['show-employees']) && (
        <li className="nav-item">
          <Link className="nav-link" to="/employees">
            <i className="fas fa-fw fa-users" />
            <span>{__('Employees')}</span>
          </Link>
        </li>
      )}
      {shiftsAreAvailable() && userHasPermissions(['show-employees-shifts', 'show-employees']) && (
        <li className="nav-item">
          <Link className="nav-link" to="/employees/shifts">
            <i className="fas fa-fw fa-calendar" />
            <span>{__('Shifts')}</span>
          </Link>
        </li>
      )}
      {shiftsAreAvailable() && userHasPermissions(['show-shifts']) && (
        <li className="nav-item">
          <Link className="nav-link" to="/shifts">
            <i className="fas fa-fw fa-calendar" />
            <span>{__('My shifts')}</span>
          </Link>
        </li>
      )}
      {userHasPermissions(['show-salary-calls']) && (
        <li className="nav-item">
          <Link className="nav-link" to="/calls/salary">
            <i className="fas fa-fw fa-phone" />
            <span>{__('My calls')}</span>
          </Link>
        </li>
      )}
      {userHasPermissions(['show-customers']) && (
        <li className="nav-item">
          <Link className="nav-link" to="/admin/customers">
            <i className="fas fa-fw fa-people-carry" />
            <span>{__('Customers')}</span>
          </Link>
        </li>
      )}
      {userHasPermissions(['show-permissions', 'show-roles']) && (
        <li className="nav-item">
          <Link className="nav-link" to="/admin/authorization">
            <i className="fas fa-fw fa-lock" />
            <span>{__('Roles')} &amp; {__('Permissions')}</span>
          </Link>
        </li>
      )}
      {userHasPermissions(['show-invoices']) && (
        <li className="nav-item">
          <Link className="nav-link" to="/admin/invoices">
            <i className="fas fa-fw fa-receipt" />
            <span>{__('Invoices')}</span>
          </Link>
        </li>
      )}
      {userHasPermissions(['show-lg-phone-numbers']) && (
        <li className="nav-item">
          <Link className="nav-link" to="/admin/lg-phone-numbers">
            <i className="fas fa-fw fa-mobile" />
            <span>{__('LG SIM cards')}</span>
          </Link>
        </li>
      )}
    </ul>
  )
}

export default LeftSidebar
