import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useLGPhoneNumbers } from '../../hooks/lg-phone-numbers'
import { __ } from '../../utils/translate';
import { userHasPermission } from '../../utils/authorization';
import Modal from '../Modal';
import lgPhoneNumbersService from '../../services/lg-phone-numbers'
import { showAlert } from '../../reducers/ui';

const LGPhoneNumbers = () => {
  const [phoneNumbers, setPhoneNumbers] = useLGPhoneNumbers()
  const [isModalOpen, setIsModalOpen] = useState()
  const [numToDelete, setNumToDelete] = useState('')
  const dispatch = useDispatch()

  const onDeleteClick = (number) => {
    setNumToDelete(number)
    setIsModalOpen(true)
  }

  const onConfirmDelete = () =>
    lgPhoneNumbersService
      .destroy(numToDelete)
      .then(() => {
        setPhoneNumbers(phoneNumbers.filter((num) => num !== numToDelete))
        dispatch(showAlert(__('LG SIM card deleted'), 'info'))
      })
      .catch(() =>
        dispatch(showAlert(__('Failed to delete LG SIM card'), 'danger')))
      .finally(() => {
        setNumToDelete('')
        setIsModalOpen(false)
      })

  return (
    <div className="card">
      <div className="card-header">
        <i className="fas fa-mobile mr-1" />
        {__('Manage LG SIM cards')}
      </div>
      <div className="card-body">
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">{__('Phone number')}</th>
              <th scope="col" />
            </tr>
          </thead>
          <tbody>
            {phoneNumbers.map((num) => (
              <tr key={num}>
                <td>{num}</td>
                <td>
                  {userHasPermission('delete-lg-phone-numbers') && (
                    <button type="button" className="float-right btn btn-light btn-sm" onClick={() => onDeleteClick(num)} title={__('delete')}>
                      <i className="fas fa-trash" />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {userHasPermission('create-lg-phone-numbers') && (
          <Link className="btn btn-primary mb-3" role="button" to="/admin/lg-phone-numbers/create">
            {__('Add LG SIM card')}
          </Link>
        )}
        {userHasPermission('delete-lg-phone-numbers') && (
          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            onConfirm={onConfirmDelete}
            title={`${__('Delete LG SIM card')} ${numToDelete}`}
            confirmTextBtnType="danger"
            confirmText={__('Delete')}
            closeText={__('Cancel')}
          >
            <span className="text-danger">{__('Are you sure you want to delete LG SIM card?')}</span>
          </Modal>
        )}
      </div>
    </div>
  )
}

export default LGPhoneNumbers
