import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = (page = 1, limit = 25) =>
  axios
    .get(`/api/phone-numbers?page=${page}&limit=${limit}`)
    .then(responseMap)

const get = (cNumber) =>
  axios
    .get(`/api/phone-numbers/${cNumber}`)
    .then(responseMap)

const put = (cNumber, { media, description }) =>
  axios
    .put(
      `/api/phone-numbers/${cNumber}`,
      { media, description }
    )
    .then(responseMap)

export default { getAll, get, put }
