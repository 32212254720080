import React from 'react'
import { useParams, Link } from 'react-router-dom'
import Table from './users/Table'
import { useUsers } from '../../hooks/users'
import { __ } from '../../utils/translate'
import { userHasPermission } from '../../utils/authorization'

const Users = () => {
  const customerId = useParams().custid
  const [users] = useUsers(customerId)

  return (
    <>
      <div className="card mb-3">
        <div className="card-header">
          <i className="fas fa-user-plus mr-1" />
          {__('Manage customer users')}
        </div>
        <div className="card-body">
          <Table users={users} />
        </div>
        <div className="card-footer small text-muted" />
      </div>

      <hr />

      <Link className="btn btn-secondary mr-1" role="button" to="/admin/customers">
        {__('Back to customers')}
      </Link>
      {userHasPermission('create-users') && (
        <Link className="btn btn-primary" role="button" to={`/admin/customers/${customerId}/users/create`}>
          {__('Create user')}
        </Link>
      )}
    </>
  )
}

export default Users
