import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Logout from './Logout'
import { __ } from '../utils/translate'
import { toggleLeftSidebar } from '../reducers/ui'

const TopNavigation = () => {
  const dispatch = useDispatch()
  const [showDropdown, setShowDropdown] = useState(false)

  const toggleDropdown = (event) => {
    event.preventDefault()
    setShowDropdown(!showDropdown)
  }

  const handleToggleSidebar = (event) => {
    event.preventDefault()
    dispatch(toggleLeftSidebar())
  }

  const liClassNames = ['nav-item', 'dropdown', 'no-arrow']
  const divClassNames = ['dropdown-menu', 'dropdown-menu-right']

  if (showDropdown) {
    liClassNames.push('show')
    divClassNames.push('show')
  }

  return (
    <nav className="navbar navbar-expand navbar-dark bg-dark static-top d-print-none">

      <Link to="/" className="navbar-brand mr-1">Xarcom.fi reports</Link>

      <button type="button" className="btn btn-link btn-sm text-white order-1 order-sm-0" id="sidebarToggle" onClick={handleToggleSidebar}>
        <i className="fas fa-bars" />
      </button>

      <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0" />

      <ul className="navbar-nav ml-auto ml-md-0">
        <li className={liClassNames.join(' ')}>
          <button type="button" className="btn btn-link nav-link dropdown-toggle" onClick={toggleDropdown} id="userDropdown" aria-haspopup="true">
            <i className="fas fa-user-circle fa-fw" />
          </button>
          <div className={divClassNames.join(' ')}>
            <Link className="dropdown-item" to="/change-password" onClick={() => setShowDropdown(false)}>
              {__('Change password')}
            </Link>
            <Link className="dropdown-item" to="/change-language" onClick={() => setShowDropdown(false)}>
              {__('Change language')}
            </Link>
            <div className="dropdown-divider" />
            <Logout className="btn btn-link dropdown-item" />
          </div>
        </li>
      </ul>

    </nav>
  )
}

export default TopNavigation
