import React from 'react'
import { __ } from '../../utils/translate'
import { excerpt } from '../../utils/strings'


const SelectEmployee = ({ employees, selectedEmployee, onEmployeeChange }) => {
  const descrLimit = 30

  if (selectedEmployee) {
    return (
      <>
        <dl className="row">
          <dt className="col-6">{__('Identifier')}</dt>
          <dd className="col-6">{selectedEmployee.identifier}</dd>
          <dt className="col-6">{__('Description')}</dt>
          <dd className="col-6">{excerpt(selectedEmployee.description, descrLimit)}</dd>
          <dt className="col-6">{__('Phone number')}</dt>
          <dd className="col-6">{selectedEmployee.phoneNumber}</dd>
        </dl>
        <button type="button" className="btn btn-secondary btn-sm" value="" onClick={onEmployeeChange}>
          {__('Choose Employee')}
        </button>
      </>
    )
  }

  return (
    <form className="form-inline">
      <select className="custom-select" value="" onChange={onEmployeeChange}>
        <option value=""> ** {__('Choose Employee')} ** </option>
        {employees.map((em) => (
          <option key={em.employeeId} value={em.employeeId}>
            {em.identifier} - {excerpt(em.description, descrLimit)} - {em.phoneNumber}
          </option>
        ))}
      </select>
    </form>
  )
}

export default SelectEmployee
