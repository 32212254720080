import React from 'react'
import { useCallsSummary } from '../hooks/calls'
import { CallCountsBarChart, CallMinutesLineChart } from './calls/Charts'
import { __ } from '../utils/translate'
import { datesToFiPeriodStr } from '../utils/date'


const CallsSummaryCharts = ({ start, end }) => {
  const [report] = useCallsSummary(start, end)

  if (!report) {
    return <></>
  }

  return (
    <div className="card mb-3">
      <div className="card-header">
        <i className="fas fa-chart-area mr-1" />
        {__('Calls summary')}: {datesToFiPeriodStr(start, end)}
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-xl-6">
            <span className="text-muted">{__('Minutes')}</span>
            <CallMinutesLineChart data={report.data} />
          </div>
          <div className="col-xl-6">
            <span className="text-muted">{__('Calls')}</span>
            <CallCountsBarChart data={report.data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallsSummaryCharts
