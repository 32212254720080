import React from 'react'
import { __ } from '../../utils/translate'

const TooManyRequests = () => (
  <div className="container">
    <div className="card mx-auto mt-5">
      <div className="card-header">
        {__('Too many requests')}
      </div>
      <div className="card-body">
        {__('Wait for couple of minutes and try again later')}
      </div>
    </div>
  </div>
)

export default TooManyRequests
