// 15.4.2020
export const dateToFiDateStr = (d) =>
  `${d.getDate()}.${d.getMonth() + 1}.${d.getFullYear()}`

// 15.4.2020 - 20.4.2020
export const datesToFiPeriodStr = (start, end) =>
  `${dateToFiDateStr(start)} - ${dateToFiDateStr(end)}`

// 2020-04-15
export const dateToDateStr = (d) => {
  const year = d.getFullYear()
  const month = (d.getMonth() + 1).toString().padStart(2, '0')
  const day = d.getDate().toString().padStart(2, '0')

  return `${year}-${month}-${day}`
}

// 2020-04
export const dateToPeriodStr = (date) => {
  const year = date.getFullYear()
  const month = `0${date.getMonth() + 1}`.slice(-2)

  return `${year}-${month}`
}

export const daysAgo = (n, from = new Date()) =>
  new Date(from.getTime() - n * 24 * 60 * 60 * 1000)

export const lastMonth = () => {
  const start = new Date()
  start.setDate(1)
  start.setMonth(start.getMonth() - 1)

  const end = new Date()
  end.setDate(0) // <-- sets date to last day of last month :D

  return [start, end]
}
