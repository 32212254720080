import React from 'react'
import ZeroRowDataTable from '../../ZeroRowDataTable'
import { sortBySeconds } from '../../../services/calls'
import { __ } from '../../../utils/translate'

const CallsInPhoneGrouppedDataTable = ({ calls }) => {
  const columns = [
    { name: __('Premium number'), selector: (r) => r.premiumNumber, sortable: true },
    { name: __('CNumber'), selector: (r) => r.cNumber, sortable: true },
    { name: `${__('Total duration')} (min)`, selector: (r) => r.minutesHuman, sortable: true, sortFunction: sortBySeconds },
    { name: __('Call count'), selector: (r) => r.calls, sortable: true },
    { name: __('Media'), selector: (r) => (r.media ? r.media : ''), sortable: true },
    { name: __('Application'), selector: (r) => r.application, sortable: true },
    { name: __('Description'), selector: (r) => r.description, sortable: false },
  ]

  return (
    <ZeroRowDataTable
      data={calls}
      columns={columns}
      zeroRowPredicate={(r) => !r.calls}
      defaultHidden
    />
  )
}

export default CallsInPhoneGrouppedDataTable
