import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = () =>
  axios
    .get('/api/employees')
    .then(responseMap)

const get = (id) =>
  axios
    .get(`/api/employees/${id}`)
    .then(responseMap)

const put = (id, { phoneNumber, identifier, description, sid, email, newPassword }) =>
  axios
    .put(
      `/api/employees/${id}`,
      { phoneNumber, identifier, description, sid, email, newPassword }
    )
    .then(responseMap)

const destroy = (id) =>
  axios
    .delete(`/api/employees/${id}`)
    .then(responseMap)

const post = ({ phoneNumber, identifier, description, sid, email, password }) =>
  axios
    .post(
      '/api/employees',
      { phoneNumber, identifier, description, sid, email, password }
    )
    .then(responseMap)

export default { getAll, get, put, destroy, post }
