import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Dashboard from './components/Dashboard'
import CallsIn from './components/CallsIn'
import CallsOut from './components/CallsOut'
import PhoneNumbers from './components/PhoneNumbers'
import EditPhoneNumber from './components/phone-numbers/Edit'
import NotFound from './components/NotFound'
import ChangePassword from './components/ChangePassword'
import Employees from './components/Employees'
import EditEmployee from './components/employees/Edit'
import CreateEmployee from './components/employees/Create'
import AdminCustomers from './components/admin/Customers'
import AdminCreateCustomer from './components/admin/customers/Create'
import AdminEditCustomer from './components/admin/customers/Edit'
import AdminCustomerApplications from './components/admin/Applications'
import AdminCustomerApplicationCreate from './components/admin/applications/Create'
import AdminCustomerApplicationEdit from './components/admin/applications/Edit'
import AdminCustomerApplicationNumbers from './components/admin/Numbers'
import AdminCustomerApplicationNumberCreate from './components/admin/numbers/Create'
import AdminCustomerApplicationNumberEdit from './components/admin/numbers/Edit'
import AdminCustomerApplicationNumberCreateRange from './components/admin/numbers/CreateRange'
import AdminCustomerUsers from './components/admin/Users'
import AdminCustomerUserCreate from './components/admin/users/Create'
import AdminCustomerUserEdit from './components/admin/users/Edit'
import AdminAuthorization from './components/admin/Authorization'
import AdminEditRole from './components/admin/roles/Edit'
import AdminCreateRole from './components/admin/roles/Create'
import AdminEditPermission from './components/admin/permissions/Edit'
import AdminCreatePermissions from './components/admin/permissions/Create'
import AdminInvoices from './components/admin/Invoices'
import EmployeesShifts from './components/EmployeesShifts'
import Shifts from './components/Shifts'
import LGPhoneNumbers from './components/admin/LGPhoneNumbers'
import LGPhoneNumbersCreate from './components/admin/lg-phone-numbers/Create'
import CallsMedia from './components/CallsMedia'
import SalaryCalls from './components/SalaryCalls'
import ChangeLanguage from './components/ChangeLanguage'

const RoutesSwitch = () => (
  <Switch>
    <Route path="/admin/customers/:custid/applications/:appid/numbers/:cnum/edit" component={AdminCustomerApplicationNumberEdit} />
    <Route path="/admin/customers/:custid/applications/:appid/numbers/create-range" component={AdminCustomerApplicationNumberCreateRange} />
    <Route path="/admin/customers/:custid/applications/:appid/numbers/create" component={AdminCustomerApplicationNumberCreate} />
    <Route path="/admin/customers/:custid/applications/:appid/numbers" component={AdminCustomerApplicationNumbers} />
    <Route path="/admin/customers/:custid/applications/:appid/edit" component={AdminCustomerApplicationEdit} />
    <Route path="/admin/customers/:custid/applications/create" component={AdminCustomerApplicationCreate} />
    <Route path="/admin/customers/:custid/applications" component={AdminCustomerApplications} />
    <Route path="/admin/customers/:custid/users/:userid/edit" component={AdminCustomerUserEdit} />
    <Route path="/admin/customers/:custid/users/create" component={AdminCustomerUserCreate} />
    <Route path="/admin/customers/:custid/users" component={AdminCustomerUsers} />
    <Route path="/admin/customers/:id/edit" component={AdminEditCustomer} />
    <Route path="/admin/customers/create" component={AdminCreateCustomer} />
    <Route path="/admin/customers" component={AdminCustomers} />
    <Route path="/admin/authorization" component={AdminAuthorization} />
    <Route path="/admin/roles/:id/edit" component={AdminEditRole} />
    <Route path="/admin/roles/create" component={AdminCreateRole} />
    <Route path="/admin/permissions/:id/edit" component={AdminEditPermission} />
    <Route path="/admin/permissions/create" component={AdminCreatePermissions} />
    <Route path="/admin/invoices" component={AdminInvoices} />
    <Route path="/admin/lg-phone-numbers/create" component={LGPhoneNumbersCreate} />
    <Route path="/admin/lg-phone-numbers" component={LGPhoneNumbers} />
    <Route path="/calls/in" component={CallsIn} />
    <Route path="/calls/out" component={CallsOut} />
    <Route path="/calls/media" component={CallsMedia} />
    <Route path="/calls/salary" component={SalaryCalls} />
    <Route path="/phone-numbers/:cnum/edit" component={EditPhoneNumber} />
    <Route path="/phone-numbers" component={PhoneNumbers} />
    <Route path="/change-password" component={ChangePassword} />
    <Route path="/change-language" component={ChangeLanguage} />
    <Route path="/employees/shifts" component={EmployeesShifts} />
    <Route path="/employees/:id/edit" component={EditEmployee} />
    <Route path="/employees/create" component={CreateEmployee} />
    <Route path="/employees" component={Employees} />
    <Route path="/shifts" component={Shifts} />
    <Route exact path="/" component={Dashboard} />
    <Route component={NotFound} />
  </Switch>
)

export default RoutesSwitch
