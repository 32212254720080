import React from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import timeGridPlugin from '@fullcalendar/timegrid'
import fiLocale from '@fullcalendar/core/locales/fi'
import enLocale from '@fullcalendar/core/locales/en-gb'
import { dateToDateStr } from '../../utils/date'
import { getLang } from '../../utils/translate'
import { strToBootstrapClasses } from '../../utils/color-hash'

const Calendar = ({ onDayClick, onEventClick, calendarState, onNeedEvents }) => {
  const handleDayClick = (info) => {
    const day = info.date
    const view = info.view.type
    onDayClick(day, view)
  }

  const handleEventClick = (click) => {
    const day = click.event.start
    const view = click.view.type
    const employeeIdentifier = click.event.extendedProps.identifier
    onEventClick(day, view, employeeIdentifier)
  }

  const handleNeedEvents = ({ start, end }, onSuccess, onFailure) =>
    onNeedEvents(
      dateToDateStr(start),
      dateToDateStr(end)
    )
      .then((events) => Promise.resolve(
        events.map((ev) => ({
          id: ev.id,
          title: ev.title,
          start: ev.start,
          end: ev.end,
          className: strToBootstrapClasses(ev.identifier),
          extendedProps: { ...ev },
        }))
      ))
      .then((events) => onSuccess(events))
      .catch((err) => onFailure(err))

  const locale = (getLang() === 'fi') ? fiLocale : enLocale
  const eventTimeFormat = { // like '14'
    hour: '2-digit',
    hour12: false,
  }

  return (
    <>
      <FullCalendar
        defaultView={calendarState.view}
        header={{
          left: 'title',
          right: 'dayGridMonth,timeGridWeek prev,next',
        }}
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        dateClick={handleDayClick}
        eventClick={handleEventClick}
        events={handleNeedEvents}
        locale={locale}
        eventTimeFormat={eventTimeFormat}
        displayEventTime={false}
        defaultDate={calendarState.day}
      />
    </>
  )
}

export default Calendar
