import axios from 'axios'

const doLogin = (email, password) =>
  axios
    .post('/api/login', { email, password })
    .then((response) => {
      if (response.data.userId) {
        return Promise.resolve(response.data)
      }

      return Promise.reject()
    })

const doLogout = () =>
  axios
    .post('/api/logout')
    .then(() => Promise.resolve(true))

const changePassword = (oldPassword, newPassword, passwordConfirmation) =>
  axios
    .put(
      '/api/change-password',
      { oldPassword, newPassword, passwordConfirmation }
    )

export default { doLogin, doLogout, changePassword }
