import React, { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTable, usePagination } from 'react-table'
import applicationNumbersService from '../../../services/application-numbers'
import { userHasPermission } from '../../../utils/authorization'
import { __ } from '../../../utils/translate'
import PaginationNav from '../../PaginationNav'


const ActionsCell = ({ row }) => {
  const number = row.original

  return (
    <>
      {userHasPermission('update-application-numbers') && (
        <Link
          className="btn btn-light btn-sm mr-1"
          role="button"
          to={`/admin/customers/${number.application.customerId}/applications/${number.application.applicationId}/numbers/${number.cNumber}/edit`}
          title={__('edit')}
        >
          <i className="fas fa-pen" />
        </Link>
      )}
    </>
  )
}

const Table = ({ columns, data, fetchData, maxPages, perPage }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      autoResetPage: false,
      pageCount: maxPages,
      initialState: {
        pageIndex: 0,
        pageSize: perPage,
      },
    },
    usePagination
  )

  useEffect(() => {
    if (fetchData) {
      fetchData({ pageIndex, pageSize })
    }
  }, [fetchData, pageIndex, pageSize])

  return (
    <>
      <div className="table-responsive">
        <table className="table table-sm" width="100%" cellSpacing="0" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      <PaginationNav
        pageIndex={pageIndex}
        maxPages={maxPages}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        gotoPage={gotoPage}
        previousPage={previousPage}
        nextPage={nextPage}
      />
    </>
  )
}

const AllApplicationNumbersTable = ({ customerId }) => {
  const [maxPages, setMaxPages] = useState(-1)
  const [data, setData] = useState([])

  const fetchData = useCallback(
    ({ pageSize, pageIndex }) => {
      applicationNumbersService
        .getAllApplicationNumbers(customerId, pageIndex + 1, pageSize)
        .then((resp) => {
          setMaxPages(Math.ceil(resp.total / resp.limit))
          setData(resp.data)
        })
    },
    [customerId]
  )

  const columns = [
    {
      Header: __('Application'),
      accessor: 'application.name',
    },
    {
      Header: __('CNumber'),
      accessor: 'cNumber',
    },
    {
      Header: __('Premium number'),
      accessor: 'premiumNumber',
    },
    {
      Header: '',
      id: 'actions',
      Cell: ActionsCell,
    },
  ]

  return (
    <Table
      columns={columns}
      data={data}
      maxPages={maxPages}
      fetchData={fetchData}
      perPage={10}
    />
  )
}

export default AllApplicationNumbersTable
