import store from './store'
import { startLoading, stopLoading, genericError } from '../reducers/ui'
import { userLogout } from '../reducers/authentication'

export const responseMap = (response) =>
  Promise.resolve(response.data)

const beforeRequest = (config) => {
  // Do something before request is sent
  store.dispatch(startLoading())
  return config
}
const onRequestError = (error) => {
  // Do something with request error
  store.dispatch(stopLoading())
  return Promise.reject(error)
}

const afterSuccessfulResponse = (response) => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  store.dispatch(stopLoading())
  return response
}

const onResponseError = (error) => {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  store.dispatch(stopLoading())

  if (error.response && error.response.status === 401) {
    store.dispatch(userLogout())
  } else if (error.response && !([400, 419].includes(error.response.status))) {
    // Let views handle validation and some other errors from backend
    store.dispatch(genericError(error))
  }

  return Promise.reject(error)
}

export const interceptors = {
  beforeRequest,
  onRequestError,
  afterSuccessfulResponse,
  onResponseError,
}
