import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = () =>
  axios
    .get('/api/admin/permissions')
    .then(responseMap)

const get = (id) =>
  axios
    .get(`/api/admin/permissions/${id}`)
    .then(responseMap)

const put = (id, description) =>
  axios
    .put(
      `/api/admin/permissions/${id}`,
      { description }
    )
    .then(responseMap)

const getMissing = () =>
  axios
    .get('/api/admin/permissions/missing')
    .then(responseMap)

const postMissing = (permssions) =>
  axios
    .post(
      '/api/admin/permissions/missing',
      permssions.map(({ name, description }) => ({ name, description }))
    )
    .then(responseMap)

export default { getAll, get, put, getMissing, postMissing }
