import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import permissionsService from '../../../services/permissions'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'

const BackButton = () => (
  <Link to={{ pathname: '/admin/authorization', hash: '#permissions' }} className="btn btn-secondary mr-1">
    {__('Back')}
  </Link>
)

const NoMissingFound = () => (
  <>
    <pre>{__('No missing permissions found')}</pre>
    <BackButton />
  </>
)

const FormRow = ({ permission }) => {
  const { name, routes } = permission
  const description = routes.map((r) => `${r.method} ${r.route}`).join(', ')

  return (
    <div className="form-group row mb-2">
      <label htmlFor={`name-${name}`} className="col-sm-3 col-form-label d-xl-none">
        {__('Permission')}
      </label>
      <div className="col-xl-3 col-sm-9">
        <input className="form-control" name={`name-${name}`} value={name} readOnly />
      </div>
      <label htmlFor={`description-${name}`} className="col-sm-3 col-form-label d-xl-none">
        {__('Description')}
      </label>
      <div className="col-xl-9 col-sm-9">
        <textarea className="form-control" rows="2" name={`description-${name}`} defaultValue={description} />
      </div>
    </div>
  )
}

const Form = ({ permissions }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const formSerialize = (formEl) => {
    /**
     * Each form row has input for name and description.
     * Loop through form inputs and create hashmap
     * permision name -> description
     */
    const values = {}
    const form = new FormData(formEl)

    form.forEach((value, input) => {
      // Input names start with prefix name- or description- followed by permission name.
      const [inputFor, ...rest] = input.split('-')
      const permission = rest.join('-')

      if (!Object.prototype.hasOwnProperty.call(values, permission)) {
        // Add permission to hashmap with initially empty description
        values[permission] = ''
      }

      if (inputFor === 'description') {
        // If form input is for description, update the hashmap with description
        values[permission] = value
      }
    })

    // Convert that hashmap to array of "permission objects"
    const data = Object.keys(values).map((key) => ({
      name: key,
      description: values[key],
    }))

    return data
  }

  const handleOnSubmit = (event) => {
    event.preventDefault()

    const formData = formSerialize(event.target)

    permissionsService
      .postMissing(formData)
      .then(() => {
        dispatch(showAlert(__('Permissions created'), 'success'))
        history.push({
          pathname: '/admin/authorization',
          hash: '#permissions',
        })
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="d-none d-xl-block">
        <div className="row mb-1">
          <div className="col-xl-3 col-6">
            {__('Permission')}
          </div>
          <div className="col-xl-9">
            {__('Description')}
          </div>
        </div>
      </div>
      {permissions.map((permission) =>
        <FormRow key={permission.name} permission={permission} />)}
      <hr />
      <BackButton />
      <button type="submit" className="btn btn-primary">
        {__('Save permissions')}
      </button>
    </form>
  )
}

const CreateForm = ({ permissions }) => (
  (permissions.length === 0) ? <NoMissingFound /> : <Form permissions={permissions} />
)

export default CreateForm
