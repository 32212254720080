import { useState, useEffect } from 'react'
import phoneNumbersService from '../services/phone-numbers'

export const usePhoneNumbers = (page, limit) => {
  const [phoneNumbers, setPhoneNumbers] = useState([])

  useEffect(() => {
    phoneNumbersService
      .getAll(page, limit)
      .then((numbers) => setPhoneNumbers(numbers.data))
  }, [page, limit])

  return [phoneNumbers, setPhoneNumbers]
}

export const usePhoneNumber = (cNumber) => {
  const [phoneNumber, setPhoneNumber] = useState(null)

  useEffect(() => {
    phoneNumbersService
      .get(cNumber)
      .then((number) => setPhoneNumber(number))
  }, [cNumber])

  return [phoneNumber, setPhoneNumber]
}
