import React, { useState } from 'react'
import MediaCallsReportForm from './calls/media/MediaCallsReportForm'
import MediaCallsReport from './calls/media/MediaCallsReport'
import { lastMonth } from '../utils/date'


const CallsMedia = () => {
  const [first, last] = lastMonth()
  const [start, setStart] = useState(first)
  const [end, setEnd] = useState(last)

  const handleOnSubmit = (from, to) => {
    setStart(from)
    setEnd(to)
  }

  return (
    <>
      <MediaCallsReportForm onSubmit={handleOnSubmit} />
      <hr />
      <MediaCallsReport start={start} end={end} />
    </>
  )
}

export default CallsMedia
