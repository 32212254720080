import React from 'react'
import { __ } from '../utils/translate'

const Modal = ({
  isOpen,
  onConfirm,
  onClose,
  title,
  confirmText,
  closeText,
  confirmTextBtnType,
  ...props
}) => {
  const ti = title || ''
  const conTxt = confirmText || __('Save')
  const cloTxt = closeText || __('Close')
  const conType = confirmTextBtnType || 'primary'

  return (
    <div className="modal" style={{ display: isOpen ? 'block' : 'none' }} tabIndex="-1" role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          {title && (
            <div className="modal-header">
              <h5 className="modal-title">{ti}</h5>
              <button type="button" className="close" aria-label="Close" onClick={onClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
          <div className="modal-body">
            {props.children /* eslint-disable-line */}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" onClick={onClose}>{cloTxt}</button>
            {conType !== 'hidden' && (
              <button type="button" className={`btn btn-${conType}`} onClick={onConfirm}>{conTxt}</button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Modal
