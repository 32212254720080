import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = (lang) =>
  axios
    .get(`/api/translations${(lang) ? `?lang=${lang}` : ''}`)
    .then(responseMap)

const changeLanguage = (lang) =>
  axios
    .put('/api/change-language', { lang })
    .then(responseMap)

export default { getAll, changeLanguage }
