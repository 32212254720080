import React from 'react'
import { useParams, Link } from 'react-router-dom'
import Table from './applications/Table'
import { useApplications } from '../../hooks/applications'
import { __ } from '../../utils/translate'
import { userHasPermission } from '../../utils/authorization'
import AllApplicationNumbersTable from './numbers/AllApplicationNumbersTable'

const Applications = () => {
  const customerId = useParams().custid
  const [applications] = useApplications(customerId)

  return (
    <>
      <div className="card mb-3">
        <div className="card-header">
          <i className="fas fa-project-diagram mr-1" />
          {__('Manage customer applications')}
        </div>
        <div className="card-body">
          <Table applications={applications} />
        </div>
        <div className="card-footer small text-muted" />
      </div>

      <Link className="btn btn-secondary mr-1" role="button" to="/admin/customers">
        {__('Back to customers')}
      </Link>
      {userHasPermission('create-applications') && (
        <Link className="btn btn-primary" role="button" to={`/admin/customers/${customerId}/applications/create`}>
          {__('Create application')}
        </Link>
      )}

      <hr />

      {userHasPermission('show-application-numbers') && (
        <div className="card mt-5">
          <div className="card-header">
            <i className="fas fa-phone-volume mr-1" />
            {__('All application numbers')}
          </div>
          <div className="card-body">
            <AllApplicationNumbersTable customerId={customerId} />
          </div>
          <div className="card-footer small text-muted" />
        </div>
      )}

    </>
  )
}

export default Applications
