import { useState, useEffect } from 'react'
import customersService from '../services/customers'

export const useCustomers = () => {
  const [customers, setCustomers] = useState([])

  useEffect(() => {
    customersService
      .getAll()
      .then((custs) => setCustomers(custs))
  }, [])

  return [customers, setCustomers]
}

export const useCustomer = (id) => {
  const [customer, setCustomer] = useState(null)

  useEffect(() => {
    customersService
      .get(id)
      .then((number) => setCustomer(number))
  }, [id])

  return [customer, setCustomer]
}
