import React from 'react'
import { useSelector } from 'react-redux'
import CallsSummaryCharts from './CallsSummaryCharts'
import { daysAgo } from '../utils/date'

const Dashboard = () => {
  const user = useSelector((state) => state.authentication)
  const customerName = user.customer.name

  const start = daysAgo(31)
  const end = daysAgo(1)

  return (
    <>
      <h1>{customerName}</h1>
      <hr />
      {user.permissions.includes('show-calls-summary') && (
        <CallsSummaryCharts start={start} end={end} />)}
    </>
  )
}

export default Dashboard
