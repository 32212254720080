import React, { useState } from 'react'

const MultiValueInput = ({
  values,
  name,
  label,
  addText = 'Add',
  onDelete,
  onAdd,
  renderErrors = () => null,
}) => {
  const [newValue, setNewValue] = useState('')

  const handleAdd = (e) => {
    const added = newValue

    e.preventDefault()

    onAdd(added)
    setNewValue('')
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAdd(e)
      // prevent submit
      return false
    }

    return true
  }

  return (
    <div className="form-row">
      <div className="form-group col-md-4">
        <label htmlFor={name}>{label}</label>
        <ul className="text-muted">
          {values.map((val) => (
            <li key={val}>
              {val}
              <button type="button" className="btn btn-sm btn-link text-muted" onClick={() => onDelete(val)}>
                <i className="fas fa-fw fa-times" />
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="form-group col-md-8">
        <small className="form-text text-muted mb-2">{addText}</small>
        <div className="input-group mb-3">
          <input
            className="form-control"
            name={name}
            value={newValue}
            onChange={(e) => setNewValue(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <div className="input-group-append">
            <button className="input-group-text" type="button" onClick={handleAdd}>
              <i className="fas fa-fw fa-plus" />
            </button>
          </div>
        </div>
      </div>
      {renderErrors()}
    </div>
  )
}

export default MultiValueInput
