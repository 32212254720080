import React, { useState } from 'react'
import { __ } from '../../utils/translate'
import PeriodPicker from './invoices/PeriodPicker'
import InvoiceReport from './invoices/Report'


const Invoices = () => {
  const [period, setPeriod] = useState(null)

  return (
    <>
      <div className="card mb-5">
        <div className="card-header container-fluid">
          <div className="row">
            <div className="col-md-9">
              <i className="fas fa-receipt mr-1" />
              {__('Invoices')}
            </div>
            <div className="col-md-3 text-right" />
          </div>
        </div>
        <div className="card-body">
          <PeriodPicker onPeriodChange={(p) => setPeriod(p)} />
        </div>
      </div>
      {period !== null && <InvoiceReport period={period} />}
    </>
  )
}

export default Invoices
