import { useState, useEffect } from 'react'
import permissionsService from '../services/permissions'


export const usePermission = (id) => {
  const [permission, setPermission] = useState(null)

  useEffect(() => {
    permissionsService
      .get(id)
      .then((perm) => setPermission(perm))
  }, [id])

  return [permission, setPermission]
}

export const usePermissions = () => {
  const [permissions, setPermissions] = useState([])

  useEffect(() => {
    permissionsService
      .getAll()
      .then((perms) => setPermissions(perms))
  }, [])

  return [permissions, setPermissions]
}
