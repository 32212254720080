import React, { useState } from 'react'
import SalaryCallsReportForm from './calls/salary/SalaryCallsReportForm'
import SalaryCallsReport from './calls/salary/SalaryCallsReport'
import { lastMonth } from '../utils/date'


const SalaryCalls = () => {
  const [first, last] = lastMonth()
  const [start, setStart] = useState(first)
  const [end, setEnd] = useState(last)

  const handleOnSubmit = (from, to) => {
    setStart(from)
    setEnd(to)
  }

  return (
    <>
      <SalaryCallsReportForm onSubmit={handleOnSubmit} />
      <hr />
      <SalaryCallsReport start={start} end={end} />
    </>
  )
}

export default SalaryCalls
