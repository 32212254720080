const LOADING_STARTED = 'LOADING_STARTED'
const LOADING_ENDED = 'LOADING_ENDED'
const TOGGLE_LEFT_SIDEBAR = 'TOGGLE_LEFT_SIDEBAR'
const GENERIC_ERROR = 'GENERIC_ERROR'
const CLEAR_GENERIC_ERROR = 'CLEAR_GENERIC_ERROR'
const SHOW_ALERT = 'SHOW_ALERT'
const DISMISS_ALERT = 'DISMISS_ALERT'


export const initialState = () => ({
  numberOfQueuedRequests: 0,
  isLeftSidebarCollapsed: false,
  genericError: null,
  alerts: [],
})


// Action creators
export const startLoading = () => ({
  type: LOADING_STARTED,
  data: { },
})
export const stopLoading = () => ({
  type: LOADING_ENDED,
  data: { },
})

export const toggleLeftSidebar = () => ({
  type: TOGGLE_LEFT_SIDEBAR,
  data: { },
})

export const genericError = (error) => ({
  type: GENERIC_ERROR,
  data: { error },
})
export const clearGenericError = () => ({
  type: CLEAR_GENERIC_ERROR,
  data: { },
})

const newAlertId = (() => {
  let alertId = 1

  return () => {
    const newId = alertId
    alertId += 1

    return newId
  }
})()
// type = primary|success|danger|warning|info ...
export const showAlert = (message, type = 'info') => ({
  type: SHOW_ALERT,
  data: {
    id: newAlertId(),
    message,
    type,
  },
})
export const dismissAlert = (id) => ({
  type: DISMISS_ALERT,
  data: { id },
})

const reducer = (state = initialState(), action) => {
  switch (action.type) {
    case LOADING_STARTED:
      return {
        ...state,
        numberOfQueuedRequests: state.numberOfQueuedRequests + 1,
      }
    case LOADING_ENDED:
      return {
        ...state,
        numberOfQueuedRequests: state.numberOfQueuedRequests - 1,
      }

    case TOGGLE_LEFT_SIDEBAR:
      return {
        ...state,
        isLeftSidebarCollapsed: !state.isLeftSidebarCollapsed,
      }

    case GENERIC_ERROR:
      return {
        ...state,
        genericError: action.data.error,
      }
    case CLEAR_GENERIC_ERROR:
      return {
        ...state,
        genericError: null,
      }

    case SHOW_ALERT:
      return {
        ...state,
        alerts: state.alerts.concat({ ...action.data }),
      }

    case DISMISS_ALERT:
      return {
        ...state,
        alerts: state.alerts.filter((a) => a.id !== action.data.id),
      }

    default:
      // Do nohing
  }

  return state
}

export default reducer
