import axios from 'axios'
import { responseMap } from '../utils/axios'
import { dateToDateStr } from '../utils/date'


export const sortBySeconds = (rowA, rowB) => rowB.seconds - rowA.seconds


const getCallsSummary = (start, end) =>
  axios
    .get(`/api/calls/summary?start=${dateToDateStr(start)}&end=${dateToDateStr(end)}`)
    .then(responseMap)

const getInCalls = (number, start, end) =>
  axios
    .get(`/api/calls/in?number=${number}&start=${dateToDateStr(start)}&end=${dateToDateStr(end)}`)
    .then(responseMap)

const getInCallsGroupByDate = (number, start, end) =>
  axios
    .get(`/api/calls/in/daily?number=${number}&start=${dateToDateStr(start)}&end=${dateToDateStr(end)}`)
    .then(responseMap)

const getInCallsGroupByPhoneNumber = (start, end) =>
  axios
    .get(`/api/calls/in/by-number?start=${dateToDateStr(start)}&end=${dateToDateStr(end)}`)
    .then(responseMap)

const getOutCallsGroupByEmployee = (start, end) =>
  axios
    .get(`/api/calls/out?start=${dateToDateStr(start)}&end=${dateToDateStr(end)}`)
    .then(responseMap)

const getOutCallsGroupByDate = (start, end) =>
  axios
    .get(`/api/calls/out/daily?start=${dateToDateStr(start)}&end=${dateToDateStr(end)}`)
    .then(responseMap)

const getMediaCalls = (start, end) =>
  axios
    .get(`/api/calls/media?start=${dateToDateStr(start)}&end=${dateToDateStr(end)}`)
    .then(responseMap)

const getSalaryCalls = (start, end) =>
  axios
    .get(`/api/calls/salary?start=${dateToDateStr(start)}&end=${dateToDateStr(end)}`)
    .then(responseMap)

export default {
  getCallsSummary,
  getInCalls,
  getInCallsGroupByDate,
  getInCallsGroupByPhoneNumber,
  getOutCallsGroupByEmployee,
  getOutCallsGroupByDate,
  getMediaCalls,
  getSalaryCalls,
}
