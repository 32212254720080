import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { dismissAlert } from '../reducers/ui'
import './Toaster.scss'

const Toaster = () => {
  const alerts = useSelector((state) => state.ui.alerts)
  const dispatch = useDispatch()

  useEffect(() => {
    // Hide alerts after 10sec. This might be called multiple times for same alert
    alerts.map((a) => setTimeout(() => dispatch(dismissAlert(a.id)), 10000))
  })

  const handleCloseClick = (id) => dispatch(dismissAlert(id))

  return (
    <div className="toaster-container">
      {alerts.map((alert) => (
        <div className={`alert alert-${alert.type}`} role="alert" key={alert.id}>
          {alert.message}
          <button type="button" className="close ml-2" aria-label="Close" onClick={() => handleCloseClick(alert.id)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      ))}
    </div>
  )
}

export default Toaster
