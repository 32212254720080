import React from 'react'
import { useSelector } from 'react-redux'
import './LoadingOverlay.scss'

const LoadingOverlay = () => {
  const isLoading = useSelector((state) => state.ui.numberOfQueuedRequests > 0)

  return (isLoading) ? <div className="loading-overlay" /> : <></>
}

export default LoadingOverlay
