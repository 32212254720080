import React, { useState } from 'react'
import Calendar from './shifts/Calendar'
import EditDay from './shifts/EditDay'
import { useEmployees } from '../hooks/employees'
import shiftsService from '../services/employees-shifts'


const EmployeesShifts = () => {
  const [employees] = useEmployees()
  const [editDay, setEditDay] = useState(null)
  const [calendarState, setCalendarState] = useState({ day: new Date(), view: 'dayGridMonth' })
  const [selectedEmployee, setSelectedEmployee] = useState(null)

  const handleEventClick = (day, view, employeeIdentifier) => {
    setSelectedEmployee(employees.find((em) => em.identifier === employeeIdentifier))
    setEditDay(day)
    setCalendarState({ ...calendarState, day, view })
  }

  const handleDayClick = (day, view) => {
    setEditDay(day)
    setCalendarState({ ...calendarState, day, view })
  }

  const handleEmpoyeeChange = (e) => {
    const employeeId = +e.target.value
    setSelectedEmployee(employees.find((em) => em.employeeId === employeeId))
  }

  if (editDay) {
    return (
      <EditDay
        day={editDay}
        selectedEmployee={selectedEmployee}
        employees={employees}
        onDone={() => setEditDay(null)}
        onEmployeeChange={handleEmpoyeeChange}
      />
    )
  }

  return (
    <>
      {employees.length > 0 && (

        <Calendar
          onDayClick={handleDayClick}
          onEventClick={handleEventClick}
          calendarState={calendarState}
          onNeedEvents={(s, e) => shiftsService.geAllForPeriod(s, e)}
        />
      )}
    </>
  )
}

export default EmployeesShifts
