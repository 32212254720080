import React from 'react'
import { Link } from 'react-router-dom'
import Tabs from '../Tabs'
import RolesTable from './authorization/RolesTable'
import PermissionsTable from './authorization/PermissionsTable'
import { __ } from '../../utils/translate'
import { useRoles } from '../../hooks/roles'
import { usePermissions } from '../../hooks/permissions'


const Authorization = () => {
  const [roles] = useRoles()
  const [permissions] = usePermissions()

  return (
    <>
      <h3>{__('Authorization')}</h3>
      <hr />
      <Tabs>
        <div label="roles" title={__('Roles')}>
          <div className="pt-3">
            <RolesTable roles={roles} />
            <hr />
            <Link className="btn btn-primary" role="button" to="/admin/roles/create">
              {__('Create role')}
            </Link>
          </div>
        </div>
        <div label="permissions" title={__('Permissions')}>
          <div className="pt-3">
            <PermissionsTable permissions={permissions} />
            <hr />
            <Link className="btn btn-primary" role="button" to="/admin/permissions/create">
              {__('Search missing permissions')}
            </Link>
          </div>
        </div>
      </Tabs>
    </>
  )
}

export default Authorization
