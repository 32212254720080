import React from 'react'
import { useParams } from 'react-router-dom'
import EditForm from './EditForm'
import { usePhoneNumber } from '../../hooks/phone-numbers'
import { __ } from '../../utils/translate'


const Edit = () => {
  const cNumber = useParams().cnum
  const [phoneNumber] = usePhoneNumber(cNumber)

  if (!phoneNumber) {
    return <></>
  }

  return (
    <>
      <h3>{__('Edit connected number')}</h3>
      <hr />
      <EditForm phoneNumber={phoneNumber} />
    </>
  )
}

export default Edit
