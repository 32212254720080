import React from 'react'
import TableRow from './TableRow'
import { __ } from '../../../utils/translate'

const Table = ({ customers, onDeleteClick }) => (
  <div className="table-responsive">
    <table className="table" id="call-data-table" width="100%" cellSpacing="0">
      <thead>
        <tr>
          <th>#</th>
          <th>{__('Name')}</th>
          <th>{__('Ivr servers')}</th>
          <th>{__('Firm Id')}</th>
          <th>{__('Created at')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {customers.map((cust) => (
          <TableRow key={cust.customerId} customer={cust} onDeleteClick={onDeleteClick} />))}
      </tbody>
    </table>
  </div>
)

export default Table
