import React, { useState } from 'react'
import { __ } from '../utils/translate'

// Some common validators.
// Validator is array [validatorFun, errorMessage]
export const required = () => [
  (value) => !!value,
  __('Required'),
]

export const minLength = (min) => [
  (value) => value.length >= min,
  `${__('Mininum length is')} ${min}`,
]
export const maxLength = (max) => [
  (value) => value.length <= max,
  `${__('Maximum length is')} ${max}`,
]

export const equal = (other, otherFieldName) => [
  (value) => value === other,
  `${__("Doesn't must match with")} ${otherFieldName}`,
]

export const onlyNumbers = () => [
  (value) => /^\d*$/.test(value),
  `${__('Only numbers')}`,
]

export const beginsWithValidPhonePrexix = () => [
  (value) => /^(04|050)/.test(value),
  __('Begins with 04 or 050'),
]

export const validEmail = () => [
  // regex from https://stackoverflow.com/a/46181
  /* eslint-disable-next-line */
  (value) => /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value),
  __('Is valid email address'),
]

export const validPeriodStr = () => [
  (value) => /^[0-9]{4}-[0-9]{2}$/.test(value),
  __('Is period in format yyyy-mm'),
]

// Helper to render validation errors
export const renderErrors = (input, isFormSubmitted = true) =>
  isFormSubmitted && input.errors.map((msg) =>
    <small key={msg} className="text-danger" style={{ display: 'block' }}>{msg}</small>)


// Validation bugs:
//  - Not applied if submitted without any "onChange". Temporary fix: apply on init
export const useInput = (type, initial = '', validators = []) => {
  const [value, setState] = useState(initial)

  const applyValidators = (newValue) =>
    validators.reduce((msgs, [f, msg]) => {
      if (!f(newValue)) {
        msgs.push(msg)
      }
      return msgs
    }, [])
  const [errors, setErrors] = useState(applyValidators(initial))

  const setValue = (val) => {
    const msgs = applyValidators(val)

    setErrors(msgs)
    setState(val)
  }

  const onChange = (event) => {
    setValue(event.target.value)
  }

  const hasErrors = () => errors.length > 0
  const reset = () => setValue(initial)

  const inputProps = { onChange, onBlur: onChange, type, value }

  return { type, value, onChange, reset, errors, hasErrors, inputProps, setValue }
}
