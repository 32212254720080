import React from 'react'

const StatisticCard = ({ icon, text, value }) => (
  <div className="card o-hidden h-100">
    <div className="card-body">
      <div className="card-body-icon">
        <i className={`fas fa-fw fa-${icon}`} />
      </div>
      <div className="mr-5">{text} {value}</div>
    </div>
  </div>
)

export default StatisticCard
