import React from 'react'
import { Link } from 'react-router-dom'
import { __ } from '../../../utils/translate'
import { userHasPermission } from '../../../utils/authorization'

const TableRow = ({ customer, onDeleteClick }) => {
  const cus = customer
  return (
    <>
      <tr>
        <td>{cus.customerId}</td>
        <td>{cus.name}</td>
        <td>{cus.ivrServers.join(', ')}</td>
        <td>{cus.fid}</td>
        <td>{cus.createdAt}</td>
        <td>
          {userHasPermission('update-customers') && (
            <Link className="btn btn-light btn-sm mr-1" role="button" to={`/admin/customers/${cus.customerId}/edit`} title={__('edit')}>
              <i className="fas fa-pen" />
            </Link>
          )}
          {userHasPermission('show-customers') && (
            <Link className="btn btn-light btn-sm mr-1" role="button" to={`/admin/customers/${cus.customerId}/applications`} title={__('applications')}>
              <i className="fas fa-project-diagram" />
            </Link>
          )}
          {userHasPermission('show-users') && (
            <Link className="btn btn-light btn-sm mr-1" role="button" to={`/admin/customers/${cus.customerId}/users`} title={__('users')}>
              <i className="fas fa-user-plus" />
            </Link>
          )}
          {userHasPermission('delete-customers') && (
            <button type="button" className="btn btn-light btn-sm" onClick={() => onDeleteClick(cus.customerId)} title={__('delete')}>
              <i className="fas fa-trash" />
            </button>
          )}
        </td>
      </tr>
    </>
  )
}

export default TableRow
