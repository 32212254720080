export const excerpt = (str, maxLen = 64) => (
  (str.length > maxLen) ? `${str.substring(0, maxLen - 3)}...` : str
)

export const randomString = (
  n = 32,
  chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!#$%&()*+,-./:;<=>?[]~^_@{}'
) => {
  const charArray = Array.from(chars)
  const randChar = () => charArray[Math.floor(Math.random() * charArray.length)]

  return Array.from('x'.repeat(n)).map(randChar).join('')
}


export const splitTextToLines = (text = '') => text.split(/\r?\n|\r|\n/g)


export const onlyNumbers = (str = '') => str.replace(/[^0-9]/g, '')
