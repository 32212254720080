import React, { useEffect, useState } from 'react'
import StatisticCard from '../StatisticCard'
import callsService from '../../../services/calls'
import { __ } from '../../../utils/translate'
import SalaryCallsTable from './SalaryCallsTable'

const SalaryCallsReport = ({ start, end }) => {
  const [report, setReport] = useState(null)

  useEffect(() => {
    callsService
      .getSalaryCalls(start, end)
      .then(setReport)
  }, [start, end])

  if (report === null) {
    return <></>
  }

  return (
    <>
      <div className="row">
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="clock" text={__('Total call duration')} value={`${report.totals.minutesHuman} min`} />
        </div>
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="phone-square" text={__('Total calls')} value={report.totals.calls} />
        </div>
      </div>
      <div className="row mt-2 text-center">
        <div className="col-xl-12">
          <small className="text-muted fs-3">
            {__('Call durations are approximate')}
          </small>
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <SalaryCallsTable calls={report.calls} />
        </div>
      </div>
    </>
  )
}

export default SalaryCallsReport
