import React, { useState } from 'react'
import CallsInReportForm from './calls/in/CallsInReportForm'
import CallsInReport from './calls/in/CallsInReport'
import CallsInDateGrouppedReport from './calls/in/CallsInDateGrouppedReport'
import CallsInPhoneGrouppedReport from './calls/in/CallsInPhoneGrouppedReport'
import { lastMonth } from '../utils/date'

const CallsIn = () => {
  const [first, last] = lastMonth()
  const [start, setStart] = useState(first)
  const [end, setEnd] = useState(last)
  const [phoneNumber, setPhoneNumber] = useState('')
  const [groupBy, setGroupBy] = useState('date')

  const handleOnSubmit = (phone, from, to, groupping) => {
    setStart(from)
    setEnd(to)
    setPhoneNumber(phone)
    setGroupBy(groupping)
  }

  let table = null

  if (groupBy === 'date') {
    table = <CallsInDateGrouppedReport phoneNumber={phoneNumber} start={start} end={end} />
  } else if (groupBy === 'phone') {
    table = <CallsInPhoneGrouppedReport start={start} end={end} />
  } else {
    table = <CallsInReport phoneNumber={phoneNumber} start={start} end={end} />
  }

  return (
    <>
      <CallsInReportForm onSubmit={handleOnSubmit} />
      <hr />
      {table}
    </>
  )
}

export default CallsIn
