import React, { Fragment } from 'react'
import { __ } from '../../../utils/translate'
import './MediaCallsTable.scss'

const SpacerRow = ({ colSpan }) => (
  <tr className="spacer">
    <td colSpan={colSpan} />
  </tr>
)

const MediaCallsTable = ({ calls, mediaTotals }) => (
  <table className="table media-calls-table">
    <thead>
      <tr>
        <th scope="col">{__('Media')}</th>
        <th scope="col">{__('Premium number')}</th>
        <th scope="col">{__('CNumber')}</th>
        <th scope="col">{__('Call count')}</th>
        <th scope="col">{`${__('Total duration')} (min)`}</th>
      </tr>
    </thead>
    <tbody>
      <SpacerRow colSpan={5} />
      {Object.keys(calls).map((media) => (
        <Fragment key={media}>
          <tr className="media-header">
            <td colSpan={5}>{media}</td>
          </tr>
          {calls[media].map((r) => (
            <tr key={r.cNumber}>
              <td />
              <td>{r.premiumNumber}</td>
              <td>{r.cNumber}</td>
              <td>{r.calls}</td>
              <td>{r.minutesHuman}</td>
            </tr>
          ))}
          <tr className="media-totals">
            <td colSpan={3}>{__('Total')}</td>
            <td>{mediaTotals[media].calls}</td>
            <td>{mediaTotals[media].minutesHuman}</td>
          </tr>
          <SpacerRow colSpan={5} />
        </Fragment>
      ))}
    </tbody>
  </table>
)

export default MediaCallsTable
