import React from 'react'
import { Link } from 'react-router-dom'
import { __ } from '../../../utils/translate'
import { userHasPermission } from '../../../utils/authorization'

const TableRow = ({ application }) => {
  const app = application

  return (
    <>
      <tr>
        <td>{app.applicationId}</td>
        <td>{app.name}</td>
        <td>
          {userHasPermission('update-applications') && (
            <Link
              className="btn btn-light btn-sm mr-1"
              role="button"
              to={`/admin/customers/${app.customerId}/applications/${app.applicationId}/edit`}
              title={__('edit')}
            >
              <i className="fas fa-pen" />
            </Link>
          )}
          {userHasPermission('show-application-numbers') && (
            <Link
              className="btn btn-light btn-sm mr-1"
              role="button"
              to={`/admin/customers/${app.customerId}/applications/${app.applicationId}/numbers`}
              title={__('numbers')}
            >
              <i className="fas fa-phone-volume" />
            </Link>
          )}
        </td>
      </tr>
    </>
  )
}

export default TableRow
