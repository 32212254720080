const LOCAL_STORAGE_KEY = 'logged-in-user'

export const getUser = () => {
  const data = window.localStorage.getItem(LOCAL_STORAGE_KEY)

  if (!data) {
    return false
  }

  let parsed

  try {
    parsed = JSON.parse(data)
  } catch (e) {
    // Corrupted data
    return false
  }

  return parsed
}

export const setUser = (user) =>
  window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(user))
