import { createStore, combineReducers } from 'redux'
import authReducer, { restoreLogin } from '../reducers/authentication'
import uiReducer from '../reducers/ui'
import { getUser } from './local-storage'

// Create redux store
const store = createStore(
  combineReducers({
    authentication: authReducer,
    ui: uiReducer,
  })
)

// store.subscribe(() => console.log(store.getState()))

// Restore persisted logged in user from local storage
const persisted = getUser()
if (persisted !== false) {
  store.dispatch(restoreLogin(persisted))
}

export default store
