import React from 'react'
import { Link } from 'react-router-dom'
import { __ } from '../../../utils/translate'
import { userHasPermission } from '../../../utils/authorization'

const TableRow = ({ number, customerId, applicationId, onDeleteClick }) => {
  const num = number
  return (
    <>
      <tr>
        <td>{num.cNumber}</td>
        <td>{num.premiumNumber}</td>
        <td>{num.media}</td>
        <td>{num.description}</td>
        <td>
          {userHasPermission('update-application-numbers') && (
            <Link
              className="btn btn-light btn-sm mr-1"
              role="button"
              to={`/admin/customers/${customerId}/applications/${applicationId}/numbers/${num.cNumber}/edit`}
              title={__('edit')}
            >
              <i className="fas fa-pen" />
            </Link>
          )}
          {userHasPermission('delete-application-numbers') && (
            <button type="button" className="btn btn-light btn-sm" onClick={() => onDeleteClick(num.cNumber)} title={__('delete')}>
              <i className="fas fa-trash" />
            </button>
          )}
        </td>
      </tr>
    </>
  )
}

export default TableRow
