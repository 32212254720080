import React from 'react'
import TableRow from './TableRow'
import { __ } from '../../../utils/translate'

const Table = ({ numbers, ...props }) => (
  <div className="table-responsive">
    <table className="table table-bordered" id="call-data-table" width="100%" cellSpacing="0">
      <thead>
        <tr>
          <th>{__('CNumber')}</th>
          <th>{__('Premium number')}</th>
          <th>{__('Media')}</th>
          <th>{__('Description')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {numbers.map((num) => (
          <TableRow key={num.cNumber} number={num} {...props} />))}
      </tbody>
    </table>
  </div>
)

export default Table
