import React from 'react'
import { useParams } from 'react-router-dom'
import { useRole } from '../../../hooks/roles'
import { usePermissions } from '../../../hooks/permissions'
import { __ } from '../../../utils/translate'
import EditForm from './EditForm'

const Edit = () => {
  const roleId = useParams().id
  const [role] = useRole(roleId)
  const [permissions] = usePermissions()


  if (!role || !permissions.length) {
    return <></>
  }

  return (
    <>
      <h3>{__('Edit role')}</h3>
      <hr />
      <EditForm role={role} permissions={permissions} />
    </>
  )
}

export default Edit
