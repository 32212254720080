import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = () =>
  axios
    .get('/api/admin/customers')
    .then(responseMap)

const post = ({ name, email, ivrServers, fid, login, password }) =>
  axios
    .post(
      '/api/admin/customers',
      {
        name,
        email,
        ivrServers: [...ivrServers],
        fid,
        login,
        password,
      }
    )
    .then(responseMap)

const destroy = (id) =>
  axios
    .delete(`/api/admin/customers/${id}`)
    .then(responseMap)

const put = (id, { name, email, ivrServers, fid }) =>
  axios
    .put(
      `/api/admin/customers/${id}`,
      {
        name,
        email,
        ivrServers: [...ivrServers],
        fid,
      }
    )
    .then(responseMap)

const get = (id) =>
  axios
    .get(`/api/admin/customers/${id}`)
    .then(responseMap)


export default { getAll, post, destroy, put, get }
