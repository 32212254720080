import React from 'react'
import Table from './phone-numbers/Table'
import { __ } from '../utils/translate'


const PhoneNumbers = () => (
  <div className="card mb-3">
    <div className="card-header">
      <i className="fas fa-phone mr-1" />
      {__('Phone numbers')}
    </div>
    <div className="card-body">
      <Table />
    </div>
    <div className="card-footer small text-muted" />
  </div>
)

export default PhoneNumbers
