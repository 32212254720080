import { useState, useEffect } from 'react'
import applicationsService from '../services/applications'

export const useApplications = (customerId) => {
  const [applications, setApplications] = useState([])

  useEffect(() => {
    applicationsService
      .getAll(customerId)
      .then((apps) => setApplications(apps))
  }, [customerId])

  return [applications, setApplications]
}

export const useApplication = (customerId, applicationId) => {
  const [application, setApplication] = useState(null)

  useEffect(() => {
    applicationsService
      .get(customerId, applicationId)
      .then((app) => setApplication(app))
  }, [customerId, applicationId])

  return [application, setApplication]
}
