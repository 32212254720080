import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInput } from '../../../hooks/input'
import rolesService from '../../../services/roles'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'


const PermissionCheckbox = ({ permission, checked, onChange }) => (
  <div className="form-check mb-2">
    <input
      type="checkbox"
      className="form-check-input"
      name={permission.name}
      value={permission.permissionId}
      checked={checked}
      onChange={onChange}
    />
    <label className="form-check-label ml-2" htmlFor={permission.name}>
      {permission.name}
    </label>
  </div>
)

const EditForm = ({ role, permissions }) => {
  const description = useInput('textarea', role.description)
  const [selected, setSelected] = useState(
    role.permissions.map((perm) => perm.permissionId)
  )

  const history = useHistory()
  const dispatch = useDispatch()


  const handlePermissionOnChange = (event) => {
    const add = !!event.target.checked
    const id = +event.target.value

    const newState = (add)
      ? selected.concat(id)
      : selected.filter((permId) => permId !== id)

    setSelected(newState)
  }

  const handleOnSubmit = (event) => {
    event.preventDefault()

    rolesService
      .put(
        role.roleId,
        {
          description: description.value,
          permissions: selected,
        }
      )
      .then(() => {
        dispatch(showAlert(__('Role updated'), 'success'))
        history.push({
          pathname: '/admin/authorization',
          hash: '#roles',
        })
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }


  return (
    <form onSubmit={handleOnSubmit}>
      <div className="row">
        <div className="col-6">
          <div className="form-group">
            <label htmlFor="name">{__('Role')}</label>
            <input className="form-control" name="name" value={role.name} disabled />
          </div>
          <div className="form-group">
            <label htmlFor="description">{__('Description')}</label>
            <textarea className="form-control" name="description" rows="5" onChange={description.onChange} value={description.value} />
          </div>
        </div>
        <div className="col-6">
          <label htmlFor="permissions">{__('Permissions')}</label>
          {permissions.map((perm) => (
            <PermissionCheckbox
              key={perm.permissionId}
              permission={perm}
              checked={selected.includes(perm.permissionId)}
              onChange={handlePermissionOnChange}
            />
          ))}
        </div>
      </div>
      <Link to={{ pathname: '/admin/authorization', hash: '#roles' }} className="btn btn-secondary mr-1 mb-3">
        {__('Cancel')}
      </Link>
      <button type="submit" className="btn btn-primary mb-3">
        {__('Save role')}
      </button>
    </form>
  )
}

export default EditForm
