import { useState, useEffect } from 'react'
import mediaService from '../services/media'

/* eslint-disable import/prefer-default-export */
export const useMedia = () => {
  const [media, setMedia] = useState([])

  useEffect(() => {
    mediaService
      .getAll()
      .then((data) => setMedia(data))
  }, [])

  return [media, setMedia]
}
