import axios from 'axios'
import { responseMap } from '../utils/axios'

const getReport = (period) =>
  axios
    .get(`/api/admin/invoices?period=${period}`)
    .then(responseMap)


export default { getReport }
