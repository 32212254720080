import React, { useState } from 'react'
import { useInvoiceReport } from '../../../hooks/invoices'
import { userHasPermissions } from '../../../utils/authorization'
import { __ } from '../../../utils/translate'

const ServerReport = ({ customer, period, ivrServer, calls }) => {
  /**
     * Note, that filedownload can't be tested when using webpack api proxy locally.
     * You can try to view them accessing directly the proxied api url.
     */
  const pdfFilename = `${period.replace('-', '')}-${customer.fid}-${ivrServer}-invoice-details.pdf`
  const pdfUrl = `/api/admin/invoices/${customer.customerId}/${period}/${ivrServer}/details/${pdfFilename}`
  const downloadUrl = `/api/admin/invoices/${customer.customerId}/${period}/${ivrServer}/details/download/${pdfFilename}`

  return (
    <>
      <h5>
        {__('Ivr server')}: <span style={{ fontWeight: 'normal' }}>{ivrServer}</span>
      </h5>
      <table className="table table-sm">
        <thead>
          <tr>
            <th scope="col" colSpan={2} />
            <th scope="col">{__('calls')}</th>
            <th scope="col">{__('minutes')}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="col">{__('Incoming calls')}</th>
            <td>{calls.incoming.description}</td>
            <td>{calls.incoming.calls}</td>
            <td>{calls.incoming.minutes}</td>
          </tr>
          {calls.outgoing.map((row, i) => (
            <tr key={row.type}>
              <th scope="col">{(i === 0) ? __('Outgoing calls') : ''}</th>
              <td>{row.description}</td>
              <td>{row.calls}</td>
              <td>{row.minutes}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {userHasPermissions(['show-invoices-details']) && (
        <p className="card-text mt-3">
          {__('Invoice details')}: <a href={pdfUrl} target="_blank" rel="noopener noreferrer">{pdfFilename}</a>
          <a href={downloadUrl} className="btn btn-sm btn-outline-secondary ml-4" download>
            <i className="fas fa-download" />
          </a>
        </p>
      )}
    </>
  )
}


const CustomerReport = ({ customer, period, reports }) => {
  const [isCollapsed, setIsCollapsed] = useState(false)

  return (
    <div className="row mb-3">
      <div className="col-xl-8">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-10">
                <h3 className="card-title">
                  <span style={{ fontWeight: 'normal' }}>{__('Service provider')}: </span>
                  {customer.name}
                </h3>
                <p className="card-text">
                  {__('Period')} {period}
                </p>
              </div>
              <div className="col-2 text-right">
                <button
                  type="button"
                  className="btn btn-light btn-sm"
                  onClick={() => setIsCollapsed(!isCollapsed)}
                >
                  <i className={`fa fa-chevron-${isCollapsed ? 'down' : 'up'}`} />
                </button>
              </div>
            </div>
            {isCollapsed || (
              <>
                {reports.map((serverReport) => (
                  <div key={serverReport.ivrServer} className="mt-5">
                    <ServerReport
                      customer={customer}
                      period={period}
                      ivrServer={serverReport.ivrServer}
                      calls={serverReport.calls}
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const Report = ({ period }) => {
  const [report] = useInvoiceReport(period)

  return (
    <>
      {report.map((customerReport) => (
        <CustomerReport
          key={customerReport.customer.customerId}
          customer={customerReport.customer}
          period={customerReport.period}
          reports={customerReport.reports}
        />
      ))}
    </>
  )
}

export default Report
