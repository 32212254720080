import React from 'react'
import TableRow from './TableRow'
import { __ } from '../../../utils/translate'

const Table = ({ applications }) => (
  <div className="table-responsive">
    <table className="table" id="call-data-table" width="100%" cellSpacing="0">
      <thead>
        <tr>
          <th>#</th>
          <th>{__('Application name')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {applications.map((app) =>
          <TableRow key={app.applicationId} application={app} />)}
      </tbody>
    </table>
  </div>
)


export default Table
