import React, { useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import StatisticCard from '../StatisticCard'
import callsService from '../../../services/calls'
import { __ } from '../../../utils/translate'
import MediaCallsTable from './MediaCallsTable'

const MediaCallsReport = ({ start, end }) => {
  const [report, setReport] = useState(null)

  useEffect(() => {
    callsService
      .getMediaCalls(start, end)
      .then(setReport)
  }, [start, end])

  if (report === null) {
    return <></>
  }

  return (
    <>
      <div className="row d-print-none">
        <div className="col">
          <span className="text-muted">{__('Minutes')}</span>
          <MediaMinutesChart data={report.mediaTotals} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="clock" text={__('Total call duration')} value={`${report.totals.minutesHuman} min`} />
        </div>
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="phone-square" text={__('Total calls')} value={report.totals.calls} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <MediaCallsTable calls={report.calls} mediaTotals={report.mediaTotals} />
        </div>
      </div>
    </>
  )
}

const MediaMinutesChart = ({ data }) => {
  const media = Object.keys(data)
  const minutes = media.map((m) => data[m].minutes)
  const colors = ['#e9724d', '#d6d727', '#92cad1', '#79ccb3', '#868686']
  const barColors = media.map((m, i) => colors[i % colors.length])

  const chartData = {
    labels: media,
    datasets: [
      {
        label: __('minutes'),
        backgroundColor: barColors,
        data: minutes,
        yAxisID: 'minutes',
      },
    ],
  }

  const options = {
    scales: {
      xAxes: [{
        gridLines: {
          display: true,
        },
        ticks: {
          display: true,
        },
      }],
      yAxes: [
        {
          id: 'minutes',
          ticks: {
            min: 0,
          },
          gridLines: {
            display: false,
          },
          position: 'left',
          scaleLabel: {
            display: true,
            labelString: __('minutes'),
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  }

  return <Bar data={chartData} height={80} options={options} />
}

export default MediaCallsReport
