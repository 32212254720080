import React from 'react'
import { useParams } from 'react-router-dom'
import EditForm from './EditForm'
import { useRoles } from '../../../hooks/roles'
import { usePermissions } from '../../../hooks/permissions'
import { useUser } from '../../../hooks/users'
import { __ } from '../../../utils/translate'


const Edit = () => {
  const params = useParams()
  const customerId = params.custid
  const userId = params.userid

  const [user] = useUser(customerId, userId)
  const [roles] = useRoles()
  const [permissions] = usePermissions()

  if (!user) {
    return <></>
  }

  return (
    <>
      <h3>{__('Edit user')}</h3>
      <hr />
      <EditForm user={user} roles={roles} permissions={permissions} />
    </>
  )
}

export default Edit
