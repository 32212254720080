import React from 'react'
import { subMonths } from 'date-fns'
import { useInput, validPeriodStr, renderErrors } from '../../../hooks/input'
import { dateToPeriodStr } from '../../../utils/date'
import { __ } from '../../../utils/translate'


const PeriodPicker = ({ onPeriodChange }) => {
  const lastMonth = subMonths(new Date(), 1)
  const defaultPeriod = dateToPeriodStr(lastMonth)

  const period = useInput('text', defaultPeriod, [validPeriodStr()])

  const quickPeriods = [
    dateToPeriodStr(subMonths(new Date(), 3)),
    dateToPeriodStr(subMonths(new Date(), 2)),
    dateToPeriodStr(subMonths(new Date(), 1)),
    dateToPeriodStr(new Date()),
  ]

  const handleSubmit = () => onPeriodChange(period.value)
  const handleQuickPeriodClick = (periodStr) => {
    period.setValue(periodStr)
    onPeriodChange(periodStr)
  }

  return (
    <div className="row">
      <div className="col-md-4 pt-2">
        <div className="input-group">
          <input type={period.type} className="form-control" value={period.value} onChange={period.onChange} />
          <div className="input-group-append">
            <button className="btn btn-secondary" type="button" onClick={handleSubmit}>{__('Show')}</button>
          </div>
        </div>
        {renderErrors(period)}
      </div>
      <div className="col-md-8 pt-2 text-right">
        {quickPeriods.map((periodStr) => (
          <button
            key={periodStr}
            type="button"
            className={`btn btn-${periodStr !== period.value ? 'outline-' : ''}secondary mr-1`}
            onClick={() => handleQuickPeriodClick(periodStr)}
          >
            {periodStr}
          </button>
        ))}
      </div>
    </div>
  )
}

export default PeriodPicker
