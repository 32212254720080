import React, { useEffect, useState } from 'react'
import shiftsService from '../../services/employees-shifts'
import { dateToDateStr } from '../../utils/date'
import EmployeeIcon from './EmployeeIcon'

const SummaryItem = ({ shift }) => (
  <p className="mb-1">
    <EmployeeIcon employee={shift} /> {shift.title}
  </p>
)

const DaySummary = ({ day, excludeEmployee }) => {
  const [shifts, setShifts] = useState([])

  useEffect(() => {
    shiftsService
      .geAllForPeriod(
        dateToDateStr(day),
        dateToDateStr(day)
      )
      .then((result) =>
        setShifts(result))
  }, [day])

  const filtered = shifts.filter((shift) =>
    !excludeEmployee || shift.identifier !== excludeEmployee.identifier)

  filtered.sort((a, b) => {
    if (a.identifier === b.identifier) {
      return a.start.localeCompare(b.start)
    }

    return a.identifier.localeCompare(b.identifier)
  })

  const split = Math.ceil(filtered.length / 2)
  const colsA = filtered.slice(0, split)
  const colsB = filtered.slice(split)

  return (
    <div className="row">
      <div className="col-lg-6">
        {colsA.map((shift) => (
          <SummaryItem key={shift.id} shift={shift} />
        ))}
      </div>
      <div className="col-lg-6">
        {colsB.map((shift) => (
          <SummaryItem key={shift.id} shift={shift} />
        ))}
      </div>
    </div>
  )
}

export default DaySummary
