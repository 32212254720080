import React from 'react'
import { useParams } from 'react-router-dom'
import EditForm from './EditForm'
import { useEmployee } from '../../hooks/employees'
import { __ } from '../../utils/translate'

const Edit = () => {
  const { id } = useParams()
  const [employee] = useEmployee(id)

  if (!employee) {
    return <></>
  }

  return (
    <>
      <h3>{__('Edit employee')}</h3>
      <hr />
      <EditForm employee={employee} />
    </>
  )
}

export default Edit
