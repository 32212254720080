import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Table from './numbers/Table'
import Modal from '../Modal'
import applicationNumbersService from '../../services/application-numbers'
import { useNumbers } from '../../hooks/application-numbers'
import { __ } from '../../utils/translate'
import { showAlert } from '../../reducers/ui'
import { userHasPermission } from '../../utils/authorization'
import { useApplication } from '../../hooks/applications'


const Numbers = () => {
  const customerId = useParams().custid
  const applicationId = useParams().appid

  const [application] = useApplication(customerId, applicationId)
  const [numbers, setNumbers] = useNumbers(customerId, applicationId)

  const [numberToDelete, setNumberToDelete] = useState(null)
  const dispatch = useDispatch()

  const handleDeleteConfirmed = () =>
    applicationNumbersService
      .destroy(
        customerId,
        applicationId,
        numberToDelete.cNumber
      )
      .then(() => {
        setNumbers(
          numbers.filter((n) => n.cNumber !== numberToDelete.cNumber)
        )
        setNumberToDelete(null)
        dispatch(showAlert(__('Connected number deleted'), 'info'))
      })

  const handleDeleteClick = (cNumber) =>
    setNumberToDelete(numbers.find((n) => n.cNumber === cNumber))

  return (
    <>
      <div className="card mb-3">
        <div className="card-header">
          <i className="fas fa-phone-volume mr-1" />
          {__('Manage customer application connected numbers')}
          {application && (
            <span> ({application.name})</span>
          )}
        </div>
        <div className="card-body">
          <Table
            numbers={numbers}
            customerId={customerId}
            applicationId={applicationId}
            onDeleteClick={handleDeleteClick}
          />
        </div>
        <div className="card-footer small text-muted" />
      </div>

      <hr />

      <Link className="btn btn-secondary mr-1" role="button" to={`/admin/customers/${customerId}/applications`}>
        {__('Back to applications')}
      </Link>
      {userHasPermission('create-application-numbers') && (
        <>
          <Link className="btn btn-primary mr-1" role="button" to={`/admin/customers/${customerId}/applications/${applicationId}/numbers/create`}>
            {__('Create connected number')}
          </Link>
          <Link className="btn btn-secondary" role="button" to={`/admin/customers/${customerId}/applications/${applicationId}/numbers/create-range`}>
            {__('Create connected number range')}
          </Link>
        </>
      )}

      {numberToDelete && (
        <Modal
          isOpen
          onConfirm={handleDeleteConfirmed}
          onClose={() => setNumberToDelete(null)}
          title={__('Delete connected number?')}
          closeText={__('Cancel')}
          confirmText={__('Delete')}
          confirmTextBtnType="danger"
        >
          <p>{__('Select Delete below if you really want to delete connected number')}</p>
        </Modal>
      )}
    </>
  )
}

export default Numbers
