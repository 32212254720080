import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInput, required } from '../../../hooks/input'
import applicationsService from '../../../services/applications'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'


const EditForm = ({ application }) => {
  const name = useInput('text', application.name, [required()])
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (name.hasErrors()) {
      return
    }

    applicationsService
      .put(
        application.customerId,
        application.applicationId,
        name.value
      )
      .then(() => {
        dispatch(showAlert(__('Application updated'), 'success'))
        history.push(`/admin/customers/${application.customerId}/applications`)
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }


  return (
    <>
      <form onSubmit={handleOnSubmit}>
        <div className="form-group">
          <label htmlFor="name">{__('Name')}</label>
          <input className="form-control" name="name" {...name.inputProps} />
          {isFormSubmitted && name.errors.map((msg) =>
            <small key={msg} className="text-danger" style={{ display: 'block' }}>{msg}</small>)}
        </div>
        <Link to={`/admin/customers/${application.customerId}/applications`} className="btn btn-secondary mr-1">
          {__('Back')}
        </Link>
        <button type="submit" className="btn btn-primary" disabled={isFormSubmitted && name.hasErrors()}>
          {__('Save application')}
        </button>
      </form>
    </>
  )
}

export default EditForm
