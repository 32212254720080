import React, { useEffect } from 'react'
import logger from '../utils/logger'
import TooManyRequests from './error/TooManyRequests'
import UnknownError from './error/UnknownError'

const GenericError = (err) => {
  useEffect(() => logger.error('Unhandled error', err), [err])

  const specificErrors = {
    429: <TooManyRequests error={err} />,
  }

  if (err.error
      && err.error.response
      && err.error.response.status
      && err.error.response.status in specificErrors) {
    return <>{specificErrors[err.error.response.status]}</>
  }

  return <UnknownError error={err} />
}

export default GenericError
