import React from 'react'
import { useParams } from 'react-router-dom'
import EditForm from './EditForm'
import { usePermission } from '../../../hooks/permissions'
import { __ } from '../../../utils/translate'


const Edit = () => {
  const permissionId = useParams().id
  const [permission] = usePermission(permissionId)

  if (!permission) {
    return <></>
  }

  return (
    <>
      <h3>{__('Edit permission')}</h3>
      <hr />
      <EditForm permission={permission} />
    </>
  )
}

export default Edit
