import { useState, useEffect } from 'react'
import invoiceService from '../services/invoices'

/* eslint-disable import/prefer-default-export */
export const useInvoiceReport = (period) => {
  const [report, setReport] = useState([])

  useEffect(() => {
    invoiceService
      .getReport(period)
      .then((rep) => setReport(rep))
  }, [period])

  return [report, report]
}
