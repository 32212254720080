import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { __ } from '../utils/translate'

const NotFound = () => {
  const location = useLocation();

  return (
    <div className="container">
      <div className="card mx-auto mt-5">
        <div className="card-header">
          404
        </div>
        <div className="card-body">
          <p>
            {__('Page not found')}
          </p>
          <p className="text-muted small">
            {__('Requested page')}: {location.pathname}
          </p>

          <Link className="btn btn-secondary float-right" to="/">
            {__('Return to homepage')}
          </Link>
        </div>
      </div>
    </div>
  )
}

export default NotFound
