import React, { useState } from 'react'
import ReactDataTable from 'react-data-table-component'
import { __ } from '../utils/translate'

const ZeroRowDataTable = ({
  data,
  columns,
  zeroRowPredicate = (row) => !row,
  defaultHidden = false,
}) => {
  const [hideZeroRows, setHideZeroRows] = useState(defaultHidden)
  const handleToggle = () => setHideZeroRows(!hideZeroRows)
  const filteredData = (hideZeroRows) ? data.filter((row) => !zeroRowPredicate(row)) : data

  return (
    <>
      <div className="row d-print-none">
        <div className="col-12 text-right">
          <div className="custom-control custom-switch">
            <input
              type="checkbox"
              className="custom-control-input"
              id="zero-row-toggle"
              checked={hideZeroRows}
              onClick={handleToggle}
              readOnly
            />
            <label className="custom-control-label" htmlFor="zero-row-toggle">
              {__('Hide empty rows')}
            </label>
          </div>
        </div>
      </div>
      <ReactDataTable data={filteredData} columns={columns} />
    </>
  )
}

export default ZeroRowDataTable
