import React, { useState } from 'react'
import CallsOutReportForm from './calls/out/CallsOutReportForm'
import CallsOutDateGrouppedReport from './calls/out/CallsOutDateGrouppedReport'
import CallsOutEmployeeGrouppedReport from './calls/out/CallsOutEmployeeGrouppedReport'
import { lastMonth } from '../utils/date'

const CallsOut = () => {
  const [first, last] = lastMonth()
  const [start, setStart] = useState(first)
  const [end, setEnd] = useState(last)
  const [groupBy, setGroupBy] = useState('date')

  const handleOnSubmit = (grouping, from, to) => {
    setStart(from)
    setEnd(to)
    setGroupBy(grouping)
  }

  return (
    <>
      <CallsOutReportForm onSubmit={handleOnSubmit} />
      <hr />
      {groupBy === 'date'
        ? <CallsOutDateGrouppedReport start={start} end={end} />
        : <CallsOutEmployeeGrouppedReport start={start} end={end} />}
    </>
  )
}

export default CallsOut
