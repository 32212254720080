import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Table from './customers/Table'
import Modal from '../Modal'
import { useCustomers } from '../../hooks/customers'
import customersService from '../../services/customers'
import { showAlert } from '../../reducers/ui'
import { __ } from '../../utils/translate'
import { userHasPermission } from '../../utils/authorization'


const Customers = () => {
  const [customers, setCustomers] = useCustomers()
  const [customerToDelete, setCustomerToDelete] = useState(null)
  const dispatch = useDispatch()

  const handleDeleteConfirmed = () =>
    customersService
      .destroy(customerToDelete.customerId)
      .then(() => {
        setCustomers(
          customers.filter((c) => c.customerId !== customerToDelete.customerId)
        )
        setCustomerToDelete(null)
        dispatch(showAlert(__('Customer deleted'), 'info'))
      })

  const handleDeleteClick = (id) =>
    setCustomerToDelete(customers.find((c) => c.customerId === id))

  return (
    <>
      <div className="card mb-3">
        <div className="card-header">
          <i className="fas fa-people-carry mr-1" />
          {__('Customers')}
        </div>
        <div className="card-body">
          <Table
            customers={customers}
            onDeleteClick={handleDeleteClick}
          />
        </div>
        <div className="card-footer small text-muted" />

        {customerToDelete && (
          <Modal
            isOpen
            onConfirm={handleDeleteConfirmed}
            onClose={() => setCustomerToDelete(null)}
            title={__('Delete customer?')}
            closeText={__('Cancel')}
            confirmText={__('Delete')}
            confirmTextBtnType="danger"
          >
            <p>{__('Select Delete below if you really want to delete customer')}</p>
          </Modal>
        )}
      </div>

      <hr />
      {userHasPermission('create-customers') && (
        <Link className="btn btn-primary mb-3" role="button" to="/admin/customers/create">
          {__('Create customer')}
        </Link>
      )}
    </>
  )
}

export default Customers
