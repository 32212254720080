import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInput, required, validEmail, maxLength, renderErrors } from '../../../hooks/input'
import customersService from '../../../services/customers'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'
import MultiValueInput from '../../MultiValueInput'


const EditForm = ({ customer }) => {
  const name = useInput('text', customer.name, [required()])
  const email = useInput('text', customer.email, [required(), validEmail()])
  const firmId = useInput('text', customer.fid, [maxLength(10)])
  const [isFormSubmitted, setFormSubmitted] = useState(false)

  const [ivrServers, setIvrServers] = useState(customer.ivrServers)

  const history = useHistory()
  const dispatch = useDispatch()

  const formHasErrors = [name, email, firmId].some((input) => input.hasErrors())

  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    customersService
      .put(
        customer.customerId,
        {
          name: name.value,
          email: email.value,
          ivrServers,
          fid: firmId.value,
        }
      )
      .then(() => {
        dispatch(showAlert(__('Customer updated'), 'success'))
        history.push('/admin/customers')
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }

  const handleAddServer = (server) => {
    if (server && !(ivrServers.includes(server))) {
      setIvrServers(ivrServers.concat(server))
    }
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form-group">
        <label htmlFor="name">{__('Name')}</label>
        <input className="form-control" name="name" {...name.inputProps} />
        {renderErrors(name, isFormSubmitted)}
      </div>
      <div className="form-group">
        <label htmlFor="name">{__('Email')}</label>
        <input className="form-control" name="email" {...email.inputProps} />
        {renderErrors(email, isFormSubmitted)}
      </div>
      <MultiValueInput
        values={ivrServers}
        name="ivrServers"
        label={__('Ivr servers')}
        onAdd={handleAddServer}
        onDelete={(server) => setIvrServers(ivrServers.filter((s) => s !== server))}
        addText={__('Add Ivr server')}
      />
      <div className="form-group">
        <label htmlFor="firmId">{__('Firm Id')}</label>
        <input className="form-control" name="firmId" {...firmId.inputProps} />
        {renderErrors(firmId, isFormSubmitted)}
      </div>

      <Link to="/admin/customers" className="btn btn-secondary mr-1">
        {__('Back')}
      </Link>
      <button type="submit" className="btn btn-primary mr-1" disabled={isFormSubmitted && formHasErrors}>
        {__('Save customer')}
      </button>
    </form>
  )
}

export default EditForm
