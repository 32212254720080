import { useState, useEffect } from 'react'
import employeesService from '../services/employees'

export const useEmployees = () => {
  const [employees, setEmployees] = useState([])

  useEffect(() => {
    employeesService
      .getAll()
      .then((emps) => setEmployees(emps))
  }, [])

  return [employees, setEmployees]
}

export const useEmployee = (id) => {
  const [employee, setEmployee] = useState(null)

  useEffect(() => {
    employeesService
      .get(id)
      .then((em) => setEmployee(em))
  }, [id])

  return [employee, setEmployee]
}
