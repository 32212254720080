import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = (customerId, applicationId) =>
  axios
    .get(
      `/api/admin/customers/${customerId}/applications/${applicationId}/numbers`
    )
    .then(responseMap)

const post = (customerId, applicationId, { cNumber, premiumNumber, media, description }) =>
  axios
    .post(
      `/api/admin/customers/${customerId}/applications/${applicationId}/numbers`,
      { cNumber, premiumNumber, media, description }
    )
    .then(responseMap)

const put = (customerId, applicationId, cNumber, { premiumNumber, media, description }) =>
  axios
    .put(
      `/api/admin/customers/${customerId}/applications/${applicationId}/numbers/${cNumber}`,
      { premiumNumber, media, description }
    )
    .then(responseMap)

const get = (customerId, applicationId, cNumber) =>
  axios
    .get(
      `/api/admin/customers/${customerId}/applications/${applicationId}/numbers/${cNumber}`
    )
    .then(responseMap)

const postRange = (
  customerId,
  applicationId,
  { cNumberPrefix, firstCNumberSuffix, lastCNumberSuffix }
) =>
  axios
    .post(
      `/api/admin/customers/${customerId}/applications/${applicationId}/numbers-range`,
      { cNumberPrefix, firstCNumberSuffix, lastCNumberSuffix }
    )
    .then(responseMap)

const postList = (customerId, applicationId, numberList) =>
  axios
    .post(
      `/api/admin/customers/${customerId}/applications/${applicationId}/numbers-list`,
      { numberList }
    )
    .then(responseMap)

const destroy = (customerId, applicationId, cNumber) =>
  axios
    .delete(
      `/api/admin/customers/${customerId}/applications/${applicationId}/numbers/${cNumber}`
    )
    .then(responseMap)

const getAllApplicationNumbers = (customerId, page, limit) =>
  axios
    .get(
      `/api/admin/customers/${customerId}/numbers?page=${page}&limit=${limit}`
    )
    .then(responseMap)

export default { getAll, post, put, get, postRange, postList, destroy, getAllApplicationNumbers }
