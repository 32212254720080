let translations = {}
let lang = 'en'

export const setTranslations = (data) => {
  translations = data.translations
  lang = data.lang
}

export const __ = (line) => {
  if (!(line in translations)) {
    return line
  }

  const translated = translations[line]
  return translated || line
}

export const getLang = () => lang
