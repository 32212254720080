import React from 'react'
import { useParams } from 'react-router-dom'
import EditForm from './EditForm'
import { useNumber } from '../../../hooks/application-numbers'
import { __ } from '../../../utils/translate'

const Edit = () => {
  const customerId = useParams().custid
  const applicationId = useParams().appid
  const cNumber = useParams().cnum
  const [number] = useNumber(customerId, applicationId, cNumber)

  if (!number) {
    return <></>
  }

  return (
    <>
      <h3>{__('Edit connected number')}</h3>
      <hr />
      <EditForm number={number} />
    </>
  )
}

export default Edit
