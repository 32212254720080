import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'


const Tabs = (props) => {
  const location = useLocation()
  const history = useHistory()

  const currentHash = location.hash.slice(1)
  /* eslint-disable-next-line */
  const labels = props.children.map((child) => child.props.label)
  const initial = (labels.includes(currentHash)) ? currentHash : labels[0] || ''
  const [selectedTab, setSelectedTab] = useState(initial)

  /* eslint-disable-next-line */
  const content = props.children.find((el) => el.props.label === selectedTab)

  const handleOnClick = (event) => {
    const { href } = event.target
    const hash = href.slice(href.indexOf('#'))

    event.preventDefault()

    setSelectedTab(hash.slice(1))
    history.replace({ ...location, hash })
  }

  const child = (el) => {
    const { label, title } = el.props

    return (
      <li key={label} className="nav-item">
        <a href={`#${label}`} onClick={handleOnClick} className={`nav-link ${label === selectedTab ? 'active' : ''}`}>
          {title || label}
        </a>
      </li>
    )
  }

  return (
    <>
      <ul className="nav nav-tabs">
          {props.children.map((el) => child(el)) /* eslint-disable-line */}
      </ul>
      <div className="tab-content">
        <div className="tab-pane active p-2">
          {content}
        </div>
      </div>
    </>
  )
}

export default Tabs
