import React from 'react'
import { useParams } from 'react-router-dom'
import EditForm from './EditForm'
import { useApplication } from '../../../hooks/applications'
import { __ } from '../../../utils/translate'

const Edit = () => {
  const customerId = useParams().custid
  const applicationId = useParams().appid
  const [application] = useApplication(customerId, applicationId)

  if (!application) {
    return <></>
  }

  return (
    <>
      <h3>{__('Edit application')}</h3>
      <hr />
      <EditForm application={application} />
    </>
  )
}

export default Edit
