import React from 'react'
import DataTable from 'react-data-table-component'
import { sortBySeconds } from '../../../services/calls'
import { __ } from '../../../utils/translate'

const CallsOutEmployeeGrouppedDataTable = ({ calls }) => {
  const columns = [
    { name: __('Phone number'), selector: (r) => r.aNumber, sortable: true },
    { name: __('Employee identifier'), selector: (r) => r.employee.identifier, sortable: true },
    { name: __('Employee description'), selector: (r) => r.employee.description, sortable: true },
    { name: __('Total duration'), selector: (r) => r.minutesHuman, sortable: true, sortFunction: sortBySeconds },
    { name: __('Call count'), selector: (r) => r.calls, sortable: true },
  ]

  return <DataTable data={calls} columns={columns} />
}

export default CallsOutEmployeeGrouppedDataTable
