import React, { useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInput, required, onlyNumbers, renderErrors, maxLength } from '../../../hooks/input'
import applicationNumbersService from '../../../services/application-numbers'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'


const Create = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const customerId = useParams().custid
  const applicationId = useParams().appid


  const cNumber = useInput('text', '', [required()])
  const premiumNumber = useInput('text', '', [onlyNumbers(), maxLength(32)])
  const media = useInput('text', '', [maxLength(64)])
  const description = useInput('textarea')

  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const formHasErrors = [
    cNumber,
    premiumNumber,
    media,
    description,
  ].some((input) => input.hasErrors())

  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    applicationNumbersService
      .post(
        customerId,
        applicationId,
        {
          cNumber: cNumber.value,
          premiumNumber: premiumNumber.value,
          media: media.value,
          description: description.value,
        }
      )
      .then(() => {
        dispatch(showAlert(__('New connected number created'), 'success'))
        history.push(`/admin/customers/${customerId}/applications/${applicationId}/numbers`)
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }

  return (
    <>
      <h3>{__('Create connected number')}</h3>

      <hr />

      <form onSubmit={handleOnSubmit}>
        <div className="form-group">
          <label htmlFor="cNumber">{__('CNumber')}</label>
          <input className="form-control" name="cNumber" {...cNumber.inputProps} />
          {renderErrors(cNumber, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="premiumNumber">{__('Premium number')}</label>
          <input className="form-control" name="premiumNumber" {...premiumNumber.inputProps} />
          {renderErrors(premiumNumber, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="media">{__('Media')}</label>
          <input className="form-control" name="media" {...media.inputProps} />
          {renderErrors(media, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="description">{__('Description')}</label>
          <textarea className="form-control" name="description" rows="5" onChange={description.onChange} value={description.value} />
        </div>
        <Link to={`/admin/customers/${customerId}/applications/${applicationId}/numbers`} className="btn btn-secondary mr-1">
          {__('Back')}
        </Link>
        <button type="submit" className="btn btn-primary" disabled={isFormSubmitted && formHasErrors}>
          {__('Save connected number')}
        </button>
      </form>
    </>
  )
}

export default Create
