import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = (customerId) =>
  axios
    .get(`/api/admin/customers/${customerId}/users`)
    .then(responseMap)

const post = (customerId, { email, password, roles }) =>
  axios
    .post(
      `/api/admin/customers/${customerId}/users`,
      { email, password, roles }
    )
    .then(responseMap)

const get = (customerId, userId) =>
  axios
    .get(`/api/admin/customers/${customerId}/users/${userId}`)
    .then(responseMap)

const put = (customerId, userId, { newPassword, roles, permissions }) =>
  axios
    .put(
      `/api/admin/customers/${customerId}/users/${userId}`,
      { newPassword, roles, permissions }
    )
    .then(responseMap)

export default { getAll, post, get, put }
