import React from 'react'
import DataTable from 'react-data-table-component'
import { __ } from '../../../utils/translate'
import { sortBySeconds } from '../../../services/calls'

const SalaryCallsTable = ({ calls }) => {
  const columns = [
    { name: __('Date'), selector: (r) => r.date, sortable: true },
    { name: __('Total duration'), selector: (r) => r.minutesHuman, sortable: true, sortFunction: sortBySeconds },
    { name: __('Call count'), selector: (r) => r.calls, sortable: true },
  ]

  return <DataTable data={calls} columns={columns} />
}

export default SalaryCallsTable
