import React, { useState } from 'react'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import { enGB, fi } from 'react-date-range/dist/locale'
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek
} from 'date-fns'
import { datesToFiPeriodStr } from '../../utils/date'
import { __, getLang } from '../../utils/translate'
import './CallsDateRangePicker.scss'


const CallsDateRangePicker = ({ start, end, onDateRangeChange }) => {
  const [showPicker, setShowPicker] = useState(false)

  const togglePicker = () => setShowPicker(!showPicker)

  const handleOnChange = (data) => {
    setShowPicker(false)
    onDateRangeChange(data)
  }

  const selection = {
    startDate: start,
    endDate: end,
    key: 'selection',
  }

  const locale = getLang() === 'fi' ? fi : enGB

  const weekStartsOn = 1
  const today = new Date()
  const yesterday = addDays(today, -1)
  const onLastWeek = addDays(today, -7)

  const staticRanges = createStaticRanges([
    {
      label: __('Today'),
      range: () => ({
        startDate: startOfDay(today),
        endDate: endOfDay(today),
      }),
    },
    {
      label: __('Yesterday'),
      range: () => ({
        startDate: startOfDay(yesterday),
        endDate: endOfDay(yesterday),
      }),
    },
    {
      label: __('This Week'),
      range: () => ({
        startDate: startOfWeek(today, { weekStartsOn }),
        endDate: endOfWeek(today, { weekStartsOn }),
      }),
    },
    {
      label: __('Last Week'),
      range: () => ({
        startDate: startOfWeek(onLastWeek, { weekStartsOn }),
        endDate: endOfWeek(onLastWeek, { weekStartsOn }),
      }),
    },
    {
      label: __('Last 7 Days'),
      range: () => ({
        startDate: startOfDay(addDays(today, -6)),
        endDate: endOfDay(today),
      }),
    },
    {
      label: __('This Month'),
      range: () => ({
        startDate: startOfMonth(today),
        endDate: endOfMonth(today),
      }),
    },
    {
      label: __('Last Month'),
      range: () => ({
        startDate: startOfMonth(addMonths(today, -1)),
        endDate: endOfMonth(addMonths(today, -1)),
      }),
    },
    {
      label: __('Last 30 Days'),
      range: () => ({
        startDate: startOfDay(addDays(today, -29)),
        endDate: endOfDay(today),
      }),
    },
  ]);

  return (
    <div className="date-picker-container">
      <input type="text" className="form-control mb-2 mr-sm-2" value={datesToFiPeriodStr(start, end)} readOnly onClick={togglePicker} />
      <div className="date-picker-overlay" style={{ display: (showPicker) ? 'block' : 'none' }}>
        <DateRangePicker
          ranges={[selection]}
          months={2}
          locale={locale}
          weekStartsOn={weekStartsOn}
          onChange={handleOnChange}
          staticRanges={staticRanges}
        />
      </div>
    </div>
  )
}

export default CallsDateRangePicker
