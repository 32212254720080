import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { useSelector } from 'react-redux'
import TopNavigation from './components/TopNavigation'
import LeftSidebar from './components/LeftSidebar'
import RoutesSwitch from './RoutesSwitch'
import Login from './components/Login'
import LoadingOverlay from './components/LoadingOverlay'
import GenericError from './components/GenericError'
import Toaster from './components/Toaster'


function App() {
  const genericError = useSelector((state) => state.ui.genericError)
  const isAuhenticated = useSelector((state) => state.authentication.loggedIn)

  if (genericError) {
    return (
      <Router>
        <GenericError error={genericError} />
      </Router>
    )
  }

  if (!isAuhenticated) {
    return (
      <Router>
        <Login />
      </Router>
    )
  }

  return (
    <Router>
      <Toaster />
      <TopNavigation />
      <div id="wrapper">
        <LeftSidebar />
        <div id="content-wrapper">
          <div className="container-fluid pb-3">
            <RoutesSwitch />
          </div>
          <footer className="sticky-footer">
            <div className="container my-auto">
              <div className="copyright text-center my-auto">
                <span>© Xarcom {(new Date()).getFullYear()}</span>
              </div>
            </div>
          </footer>
        </div>
      </div>
      <LoadingOverlay />
    </Router>
  )
}

export default App
