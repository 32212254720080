import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { maxLength, useInput } from '../../hooks/input'
import phoneNumbersService from '../../services/phone-numbers'
import { showAlert } from '../../reducers/ui'
import { __ } from '../../utils/translate'
import { useMedia } from '../../hooks/media'
import TypeaheadInput from '../TypeaheadInput'

const EditForm = ({ phoneNumber }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [allMedia] = useMedia()

  const media = useInput('text', phoneNumber.media, [maxLength(64)])
  const description = useInput('textarea', phoneNumber.description)

  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const formHasErrors = [media, description].some((input) => input.hasErrors())

  const handleOnSubmit = (e) => {
    e.preventDefault()

    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    phoneNumbersService
      .put(
        phoneNumber.cNumber,
        {
          media: media.value,
          description: description.value,
        }
      )
      .then(() => {
        dispatch(showAlert(__('Connected number updated'), 'success'))
        history.push('/phone-numbers')
      })
      .catch(() =>
        dispatch(showAlert(__('Connected number update failed'), 'danger')))
  }

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form-group">
        <label htmlFor="c-number">{__('Phone number')}</label>
        <input type="text" className="form-control" name="c-number" value={phoneNumber.phoneNumber} disabled />
      </div>
      <div className="form-group">
        <label htmlFor="application">{__('Application')}</label>
        <input type="text" className="form-control" nqame="application" value={phoneNumber.application.name} disabled />
      </div>
      <TypeaheadInput
        input={media}
        name="media"
        label={__('Media')}
        isFormSubmitted={isFormSubmitted}
        dataList={allMedia}
      />
      <div className="form-group">
        <label htmlFor="description">{__('Description')}</label>
        <textarea className="form-control" name="description" rows="5" onChange={description.onChange} value={description.value} />
      </div>

      <Link to="/phone-numbers" className="btn btn-secondary mr-1 mb-3">
        {__('Back')}
      </Link>
      <button type="submit" className="btn btn-primary mb-3">
        {__('Save connected number')}
      </button>
    </form>
  )
}

export default EditForm
