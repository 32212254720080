import React from 'react'
import { Bar } from 'react-chartjs-2'
import CallsOutEmployeeGrouppedDataTable from './CallsOutEmployeeGrouppedDataTable'
import StatisticCard from '../StatisticCard'
import { useCallsOutGroupByEmployeeReport } from '../../../hooks/calls'
import { __ } from '../../../utils/translate'
import colorHash from '../../../utils/color-hash'


const CallsOutEmployeeGrouppedReport = ({ start, end }) => {
  const [report] = useCallsOutGroupByEmployeeReport(start, end)

  return (report !== null) ? (
    <>
      <div className="row d-print-none">
        <div className="col">
          <span className="text-muted">{__('Minutes')}</span>
          <EmployeeMinutesBarChart calls={report.calls} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="clock" text={__('Total call duration')} value={`${report.totals.minutesHuman} min`} />
        </div>
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="phone-square" text={__('Total calls')} value={report.totals.calls} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <CallsOutEmployeeGrouppedDataTable calls={report.calls} />
        </div>
      </div>
    </>
  ) : <></>
}


const EmployeeMinutesBarChart = ({ calls }) => {
  const data = calls.map((row) => row.minutes)
  const xLabels = calls.map((row) => row.aNumber)
  const colors = calls.map((row) => `#${colorHash(row.aNumber)}`)

  const barData = {
    labels: xLabels,
    datasets: [
      {
        label: __('minutes'),
        data,
        backgroundColor: colors,
      },
    ],
  }

  const barOptions = {
    scales: {
      yAxes: [{
        gridLines: {
          display: false,
        },
        ticks: {
          display: true,
          min: 0,
        },
      }],
    },
    legend: {
      display: false,
    },
  }

  return <Bar data={barData} options={barOptions} height={120} />
}

export default CallsOutEmployeeGrouppedReport
