import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInput, required, validEmail, maxLength, renderErrors } from '../../../hooks/input'
import customersService from '../../../services/customers'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'
import MultiValueInput from '../../MultiValueInput'


const Create = () => {
  const name = useInput('text', '', [required()])
  const email = useInput('text', '', [required(), validEmail()])
  const firmId = useInput('text', '', [maxLength(10)])
  const login = useInput('text', '', [required(), validEmail()])
  const password = useInput('text', '', [required()])

  const [ivrServers, setIvrServers] = useState([])

  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [loginIsModified, setLoginIsModified] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const formHasErrors = [
    name,
    email,
    password,
    firmId,
    login,
    password,
  ].some((input) => input.hasErrors())

  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    customersService
      .post(
        {
          name: name.value,
          email: email.value,
          ivrServers,
          fid: firmId.value,
          login: login.value,
          password: password.value,
        }
      )
      .then(() => {
        dispatch(showAlert(__('Customer created'), 'success'))
        history.push('/admin/customers')
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }

  // Bind customer email and user email together when user email is not modified by user
  const handleEmailOnChange = (event) => {
    email.onChange(event)

    if (!loginIsModified) {
      login.setValue(event.target.value)
    }
  }

  const handleLoginOnChange = (event) => {
    setLoginIsModified(true)
    login.onChange(event)
  }

  const handleAddServer = (server) => {
    if (server && !(ivrServers.includes(server))) {
      setIvrServers(ivrServers.concat(server))
    }
  }

  return (
    <>
      <h3>{__('Create customer')}</h3>
      <hr />

      <form onSubmit={handleOnSubmit}>
        <div className="form-group">
          <label htmlFor="name">{__('Name')}</label>
          <input className="form-control" name="name" {...name.inputProps} />
          {renderErrors(name, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="email">{__('Email')}</label>
          <input
            className="form-control"
            name="email"
            type={email.type}
            value={email.value}
            onChange={handleEmailOnChange}
            onBlur={email.onBlur}
          />
          {renderErrors(email, isFormSubmitted)}
        </div>
        <MultiValueInput
          values={ivrServers}
          name="ivrServers"
          label={__('Ivr servers')}
          onAdd={handleAddServer}
          onDelete={(server) => setIvrServers(ivrServers.filter((s) => s !== server))}
          addText={__('Add Ivr server')}
        />
        <div className="form-group">
          <label htmlFor="firmId">{__('Firm Id')}</label>
          <input className="form-control" name="firmId" {...firmId.inputProps} />
          {renderErrors(firmId, isFormSubmitted)}
        </div>

        <hr />

        <div className="form-group">
          <label htmlFor="login">{__('Email (login)')}</label>
          <input
            className="form-control"
            name="login"
            type={login.type}
            value={login.value}
            onChange={handleLoginOnChange}
            onBlur={login.onBlur}
          />
          {renderErrors(login, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="password">{__('Password')}</label>
          <input className="form-control" name="password" {...password.inputProps} />
          <small className="form-text text-muted">
            {__('Set some dummy password and encourage customer to change it')}
          </small>
          {renderErrors(password, isFormSubmitted)}
        </div>

        <Link to="/admin/customers" className="btn btn-secondary mr-1">
          {__('Cancel')}
        </Link>
        <button type="submit" className="btn btn-primary" disabled={isFormSubmitted && formHasErrors}>
          {__('Save customer')}
        </button>
      </form>
    </>
  )
}

export default Create
