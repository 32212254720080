import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = () =>
  axios
    .get('/api/admin/roles')
    .then(responseMap)

const post = ({ name, description }) =>
  axios
    .post(
      '/api/admin/roles',
      { name, description }
    )
    .then(responseMap)

const get = (id) =>
  axios
    .get(`/api/admin/roles/${id}`)
    .then(responseMap)

const put = (id, { description, permissions }) =>
  axios
    .put(
      `/api/admin/roles/${id}`,
      { description, permissions: [...permissions] }
    )
    .then(responseMap)

export default { getAll, post, get, put }
