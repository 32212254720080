import React from 'react'
import TableRow from './TableRow'
import { __ } from '../../utils/translate'

const Table = ({ employees, onDeleteClick }) => (
  <div className="table-responsive">
    <table className="table" id="call-data-table" width="100%" cellSpacing="0">
      <thead>
        <tr>
          <th>{__('Phone number')}</th>
          <th>{__('Identifier')}</th>
          <th>{__('Email (login)')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {employees.map((employee) => (
          <TableRow key={employee.employeeId} employee={employee} onDeleteClick={onDeleteClick} />
        ))}
      </tbody>
    </table>
  </div>
)

export default Table
