import React from 'react'
import { useParams } from 'react-router-dom'
import EditForm from './EditForm'
import { useCustomer } from '../../../hooks/customers'
import { __ } from '../../../utils/translate'

const Edit = () => {
  const { id } = useParams()
  const [customer] = useCustomer(id)

  if (!customer) {
    return <></>
  }

  return (
    <>
      <h3>{__('Edit customer')}</h3>
      <hr />
      <EditForm customer={customer} />
    </>
  )
}

export default Edit
