import React from 'react'
import { Link } from 'react-router-dom'
import { __ } from '../../../utils/translate'

const TableRow = ({ role }) => (
  <tr>
    <td>{role.roleId}</td>
    <td>{role.name}</td>
    <td><span className="badge badge-pill badge-secondary">{role.permissions.length}</span></td>
    <td>
      <Link
        className="btn btn-light btn-sm mr-1"
        role="button"
        to={`/admin/roles/${role.roleId}/edit`}
        title={__('edit')}
      >
        <i className="fas fa-pen" />
      </Link>
    </td>
  </tr>
)

const RolesTable = ({ roles }) => (
  <div className="table-responsive">
    <table className="table" width="100%" cellSpacing="0">
      <thead>
        <tr>
          <th>#</th>
          <th>{__('Role')}</th>
          <th>{__('Permissions')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {roles.map((rol) => (
          <TableRow key={rol.roleId} role={rol} />))}
      </tbody>
    </table>
  </div>
)

export default RolesTable
