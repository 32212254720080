/* eslint-disable no-bitwise */

export const hashCode = (str) => {
  let hash = 0
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash)
  }
  return hash
}

export const intToRgb = (int) => {
  const c = (int & 0x00FFFFFF).toString(16).toUpperCase()
  return '00000'.substring(0, 6 - c.length) + c
}

/* eslint-enable no-bitwise */

export const strToBootstrapColor = (str) => {
  const bsColorNames = [
    'blue',
    'indigo',
    'purple',
    'pink',
    'red',
    'orange',
    'yellow',
    'green',
    'teal',
    'cyan',
    'gray',
    'gray-dark',
  ]

  if (!str) {
    return 'gray';
  }

  return bsColorNames[
    [...Array(str.length).keys()].reduce(
      (carry, idx) =>
        (carry + str.charCodeAt(idx)) % bsColorNames.length,
      0
    )
  ]
}

export const strToBootstrapClasses = (str) => {
  const bg = strToBootstrapColor(str)
  const txt = (['yellow', 'cyan'].includes(bg)) ? 'black' : 'white'
  return `bg-${bg} text-${txt}`
}

export const chartColor = (str) => {
  // https://learnui.design/tools/data-color-picker.html
  const colours = [
    '#062a5c',
    '#473170',
    '#7b347a',
    '#ad3577',
    '#d63f69',
    '#f35952',
    '#ff7d35',
    '#ffa600',
  ]

  return colours[
    [...Array(str.length).keys()].reduce(
      (carry, idx) =>
        (carry + str.charCodeAt(idx)) % colours.length,
      0
    )
  ]
}

const colorHash = (str) => intToRgb(hashCode(str))

export default colorHash
