import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import Table from './employees/Table'
import Modal from './Modal'
import { useEmployees } from '../hooks/employees'
import employeesService from '../services/employees'
import { showAlert } from '../reducers/ui'
import { __ } from '../utils/translate'
import { userHasPermission } from '../utils/authorization'


const Employees = () => {
  const [employees, setEmployees] = useEmployees()
  const [employeeToDelete, setEmployeeToDelete] = useState(null)
  const dispatch = useDispatch()

  const handleDeleteConfirmed = () =>
    employeesService
      .destroy(employeeToDelete.employeeId)
      .then(() => {
        setEmployees(
          employees.filter((e) => e.employeeId !== employeeToDelete.employeeId)
        )
        setEmployeeToDelete(null)
        dispatch(showAlert(__('Employee deleted'), 'info'))
      })

  const handleDeleteClick = (id) =>
    setEmployeeToDelete(employees.find((e) => e.employeeId === id))

  return (
    <>
      <div className="card mb-3">
        <div className="card-header">
          <i className="fas fa-users mr-1" />
          {__('Employees')}
        </div>
        <div className="card-body">
          <Table
            employees={employees}
            onDeleteClick={handleDeleteClick}
          />
        </div>
        <div className="card-footer small text-muted" />
        {employeeToDelete && (
          <Modal
            isOpen
            onConfirm={handleDeleteConfirmed}
            onClose={() => setEmployeeToDelete(null)}
            title={__('Delete employee?')}
            closeText={__('Cancel')}
            confirmText={__('Delete')}
            confirmTextBtnType="danger"
          >
            <p>{__('Select Delete below if you really want to delete employee')}</p>
          </Modal>
        )}
      </div>

      <hr />
      {userHasPermission('create-employees') && (
        <Link className="btn btn-primary mb-3" role="button" to="/employees/create">
          {__('Create employee')}
        </Link>
      )}
    </>
  )
}

export default Employees
