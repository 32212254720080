import { useState, useEffect } from 'react'
import usersService from '../services/users'

export const useUser = (customerId, userId) => {
  const [user, setUser] = useState(null)

  useEffect(() => {
    usersService
      .get(customerId, userId)
      .then((usr) => setUser(usr))
  }, [customerId, userId])

  return [user, setUser]
}

export const useUsers = (customerId) => {
  const [users, setUsers] = useState([])

  useEffect(() => {
    usersService
      .getAll(customerId)
      .then((usrs) => setUsers(usrs))
  }, [customerId])

  return [users, setUsers]
}
