import React, { useState } from 'react'
import Calendar from './shifts/Calendar'
import EditShifts from './shifts/EditShifts'
import shiftsService from '../services/shifts'

const Shifts = () => {
  const [editDay, setEditDay] = useState(null)
  const [calendarState, setCalendarState] = useState({ day: new Date(), view: 'dayGridMonth' })

  const handleEventClick = (day, view) => {
    setEditDay(day)
    setCalendarState({ ...calendarState, day, view })
  }

  const handleDayClick = (day, view) => {
    setEditDay(day)
    setCalendarState({ ...calendarState, day, view })
  }

  if (editDay) {
    return (
      <EditShifts
        day={editDay}
        onDone={() => setEditDay(null)}
      />
    )
  }

  return (
    <Calendar
      onDayClick={handleDayClick}
      onEventClick={handleEventClick}
      calendarState={calendarState}
      onNeedEvents={(s, e) => shiftsService.geAllForPeriod(s, e)}
    />
  )
}

export default Shifts
