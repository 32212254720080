import React, { useState } from 'react'
import { __ } from '../../../utils/translate'
import ClickableParagraph from '../../ClickableParagraph'

const EditPermissions = ({ permissions, selected, onChange }) => {
  const [showEdit, setShowEdit] = useState(false)

  const toggleShowEdit = () => setShowEdit(!showEdit)

  if (showEdit) {
    return (
      <>
        <div className="form-group mb-3">
          <ClickableParagraph onClick={toggleShowEdit} icon="caret-up" text={__('Extra permissions')} />
          {permissions.map((perm) => (
            <div key={perm.permissionId} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value={perm.permissionId}
                checked={selected.includes(perm.permissionId)}
                onChange={onChange}
              />
              <label className="form-check-label" htmlFor="permission">{perm.name}</label>
            </div>
          ))}
        </div>
      </>
    )
  }

  return (
    <ClickableParagraph onClick={toggleShowEdit}>
      <i className="fa fa-caret-down mr-1" />
      <strong className="mr-1">{__('Extra permissions')}:</strong>
      <span className="badge badge-pill badge-secondary">{selected.length}</span>
    </ClickableParagraph>
  )
}

export default EditPermissions
