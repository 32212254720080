import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import loginService from '../services/login'
import { useInput, required, minLength, equal, renderErrors } from '../hooks/input'
import { __ } from '../utils/translate'
import { showAlert } from '../reducers/ui'

const ChangePassword = () => {
  const userEmail = useSelector((state) => state.authentication.email)
  const oldPassword = useInput('password', '', [required()])
  const newPassword = useInput('password', '', [required(), minLength(8)])
  const passConfirm = useInput('password', '', [required(), equal(newPassword.value, __('New password'))])
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const history = useHistory()
  const dispatch = useDispatch()

  const formHasErrors = [
    oldPassword.hasErrors(),
    newPassword.hasErrors(),
    passConfirm.hasErrors(),
  ].some((x) => x)


  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    loginService
      .changePassword(
        oldPassword.value,
        newPassword.value,
        passConfirm.value
      )
      .then(() => {
        oldPassword.reset()
        newPassword.reset()
        passConfirm.reset()

        dispatch(showAlert(__('Password changed'), 'info'))
        history.push('/')
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }


  return (
    <>
      <h3>{__('Change password')}</h3>
      <hr />

      <div className="row">
        <div className="col">
          <form className="mb-3" onSubmit={handleOnSubmit}>
            <div className="form-group">
              <label htmlFor="email">{__('Email (login)')}</label>
              <input className="form-control" name="email" value={userEmail} disabled />
            </div>
            <div className="form-group">
              <label htmlFor="old-password">{__('Current password')}</label>
              <input className="form-control" {...oldPassword.inputProps} aria-describedby="pwd-help" />
              <small id="pwd-help" className="form-text text-muted">{__('Please enter your current password')}</small>
              {renderErrors(oldPassword, isFormSubmitted)}
            </div>
            <hr />
            <div className="form-group">
              <label htmlFor="new-password">{__('New password')}</label>
              <input className="form-control" {...newPassword.inputProps} autoComplete="off" />
              {renderErrors(newPassword, isFormSubmitted)}
            </div>
            <div className="form-group">
              <label htmlFor="new-password-confirmation">{__('Password confirmation')}</label>
              <input className="form-control" {...passConfirm.inputProps} autoComplete="off" />
              {renderErrors(passConfirm, isFormSubmitted)}
            </div>
            <button type="submit" className="btn btn-primary" disabled={isFormSubmitted && formHasErrors}>{__('Change password')}</button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ChangePassword
