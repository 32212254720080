import React, { useEffect, useState } from 'react'
import { isSameDay, isBefore } from 'date-fns'
import Hour from './Hour'
import shiftsService from '../../services/shifts'
import { __ } from '../../utils/translate'
import { dateToFiDateStr, dateToDateStr } from '../../utils/date'
import { userHasPermission } from '../../utils/authorization'

/**
 * Edit view for employee user.
 *
 * Employee can edit only employee's own shifts.
 * There is some duplicate code with customer user's EditDay
 * component. Should refactor these...
 */
const EditShifts = ({ day, onDone }) => {
  const [selected, setSelected] = useState([])

  const shiftsToHours = (shifts) => shifts.map((s) => s.hour)
  const hours = Array.from(Array(24).keys())

  const now = new Date()
  const isDayInPast = isBefore(day, now) && !isSameDay(day, now)
  const isHourDisabled = (h) => {
    if (isDayInPast) {
      return true
    }

    return (isSameDay(day, now)) && h < now.getHours()
  }

  const headerMargin = (isDayInPast) ? 3 : 5

  useEffect(() => {
    shiftsService
      .get(
        dateToDateStr(day)
      )
      .then((shifts) => {
        setSelected(shiftsToHours(shifts))
      })
  }, [day])


  const handleOnChange = (event) => {
    const add = !!event.target.checked
    const hour = +event.target.value

    const newState = (add)
      ? selected.concat(hour)
      : selected.filter((h) => h !== hour)

    setSelected(newState)
  }

  const handleSave = () => {
    shiftsService
      .put(
        dateToDateStr(day),
        selected.filter((h) => !isHourDisabled(h))
      )
      .then(() => onDone())
      .catch((error) => window.alert(error.response.data.errors.join(', ')))
  }


  return (
    <div className="container">
      <div className="row">

        <div className="col">
          <h3 className={`mb-${headerMargin}`}>
            {__('Shifts')}
            <strong className="ml-3">{dateToFiDateStr(day)}</strong>
          </h3>
          {isDayInPast && userHasPermission('update-shifts') && (
            <p>
              <small className="text-danger">{__('Past shifts can\'t be modified')}</small>
            </p>
          )}
        </div>
      </div>
      <div className="row text-center">
        <div className="col-md-6">
          {hours.slice(0, 12).map((h) => (
            <Hour
              key={h}
              hour={h}
              checked={selected.includes(h)}
              onChange={handleOnChange}
              isDisabled={isHourDisabled(h) || !userHasPermission('update-shifts')}
            />
          ))}
        </div>
        <div className="col-md-6">
          {hours.slice(12).map((h) => (
            <Hour
              key={h}
              hour={h}
              checked={selected.includes(h)}
              onChange={handleOnChange}
              isDisabled={isHourDisabled(h) || !userHasPermission('update-shifts')}
            />
          ))}
        </div>
      </div>
      <div className="row mt-4 mb-4">
        <div className="col">
          <button type="button" className="btn btn-secondary btn-lg" onClick={onDone}>
            {__('Back')}
          </button>
        </div>
        <div className="col text-right">
          {userHasPermission('update-shifts') && (
            <button type="button" className="btn btn-primary btn-lg" onClick={handleSave} disabled={isDayInPast}>
              {__('Save')}
            </button>
          )}
        </div>
      </div>
    </div>
  )
}

export default EditShifts
