import React, { useState } from 'react'
import CallsDateRangePicker from '../CallsDateRangePicker'
import { __ } from '../../../utils/translate'
import { lastMonth } from '../../../utils/date'


const CallsOutReportForm = ({ onSubmit }) => {
  const [first, last] = lastMonth()
  const [start, setStart] = useState(first)
  const [end, setEnd] = useState(last)
  const [grouping, setGrouping] = useState('date')

  const handleDateRangeChange = (data) => {
    setStart(data.selection.startDate)
    setEnd(data.selection.endDate)
  }

  /**
   * BS4 "input group toggle" and react doesn't go well together.
   * The input radio onchange is never triggered by clicking
   * the "button labels". So there are separate onClick handlers
   * defined to labels, which toggles the grouping state...
   *
   * Disable related eslint rules for this file...
   */
  /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
  /* eslint-disable jsx-a11y/click-events-have-key-events */
  const handleGroupingChange = (e) => {
    if (e.target.checked) {
      setGrouping(e.target.value)
    }
  }

  const handleLabelClick = (which) => {
    setGrouping(which)
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    onSubmit(grouping, start, end)
  }

  return (
    <form className="form-inline" onSubmit={handleOnSubmit}>
      <div className="btn-group btn-group-toggle mr-1">
        <label className={`btn btn-light ${grouping === 'date' ? 'active' : ''}`} htmlFor="date" onClick={() => handleLabelClick('date')}>
          <input className="form-control" type="radio" name="grouping" value="date" checked={grouping === 'date'} onChange={handleGroupingChange} />
          {__('Group by date')}
        </label>
        <label className={`btn btn-light ${grouping === 'employee' ? 'active' : ''}`} htmlFor="employee" onClick={() => handleLabelClick('employee')}>
          <input className="form-control" type="radio" name="grouping" value="employee" checked={grouping === 'employee'} onChange={handleGroupingChange} />
          {__('Group by employee')}
        </label>
      </div>

      <label className="sr-only" htmlFor="period">
        {__('Period')}
      </label>
      <CallsDateRangePicker start={start} end={end} onDateRangeChange={handleDateRangeChange} />

      <button type="submit" className="btn btn-primary mb-2 d-print-none">
        {__('Show report')}
      </button>
    </form>
  )
}

export default CallsOutReportForm
