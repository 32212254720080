import React from 'react'
import { renderErrors } from '../hooks/input'

const TypeaheadInput = ({ input, name, label, dataList, isFormSubmitted }) => (
  <div className="form-group">
    <label htmlFor={name}>{label}</label>
    <input className="form-control" name={name} list={`typeahead-${name}-list`} {...input.inputProps} />
    {renderErrors(input, isFormSubmitted)}
    <datalist id={`typeahead-${name}-list`}>
      {dataList.map((val) => (
        <option key={val} value={val} />
      ))}
    </datalist>
  </div>
)

export default TypeaheadInput
