import React from 'react'
import { Link } from 'react-router-dom'
import { __ } from '../../../utils/translate'
import { excerpt } from '../../../utils/strings'


const TableRow = ({ permission }) => (
  <tr>
    <td>{permission.permissionId}</td>
    <td>{permission.name}</td>
    <td>{excerpt(permission.description, 50)}</td>
    <td>
      <Link
        className="btn btn-light btn-sm mr-1"
        role="button"
        to={`/admin/permissions/${permission.permissionId}/edit`}
        title={__('edit')}
      >
        <i className="fas fa-pen" />
      </Link>
    </td>
  </tr>
)


const PermissionsTable = ({ permissions }) => (
  <div className="table-responsive">
    <table className="table" width="100%" cellSpacing="0">
      <thead>
        <tr>
          <th>#</th>
          <th>{__('Permission')}</th>
          <th>{__('Description')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {permissions.map((perm) => (
          <TableRow key={perm.permissionId} permission={perm} />))}
      </tbody>
    </table>
  </div>
)


export default PermissionsTable
