import { useState, useEffect } from 'react'
import lgPhoneNumbersService from '../services/lg-phone-numbers'

/* eslint-disable import/prefer-default-export */
export const useLGPhoneNumbers = () => {
  const [phoneNumbers, setPhoneNumbers] = useState([])

  useEffect(() => {
    lgPhoneNumbersService
      .getAll()
      .then((rep) => setPhoneNumbers(rep))
  }, [])

  return [phoneNumbers, setPhoneNumbers]
}
