import React from 'react'
import { Bar } from 'react-chartjs-2'
import StatisticCard from '../StatisticCard'
import { useCallsInGroupByPhoneNumberReport } from '../../../hooks/calls'
import { __ } from '../../../utils/translate'
import CallsInPhoneGrouppedDataTable from './CallsInPhoneGrouppedDataTable'


const CallsInPhoneGrouppedReport = ({ start, end }) => {
  const [report] = useCallsInGroupByPhoneNumberReport(start, end)

  if (report === null) {
    return <></>
  }

  return (
    <>
      <div className="row d-print-none">
        <div className="col">
          <PhoneNumberBarsChart data={report.calls} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="clock" text={__('Total call duration')} value={`${report.totals.minutesHuman} min`} />
        </div>
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="phone-square" text={__('Total calls')} value={report.totals.calls} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <CallsInPhoneGrouppedDataTable calls={report.calls} />
        </div>
      </div>
    </>
  )
}


const PhoneNumberBarsChart = ({ data }) => {
  const filtered = data.filter((row) => row.seconds > 0)

  const chartData = {
    labels: filtered.map((row) => row.cNumber),
    datasets: [
      {
        label: __('calls'),
        backgroundColor: 'rgba(143, 188, 148, 1.0)',
        // borderColor: 'rgba(204,213,2,1)',
        // borderWidth: 3,
        data: filtered.map((row) => row.calls),
        yAxisID: 'calls',
      },
      {
        label: __('minutes'),
        backgroundColor: 'rgba(197, 233, 155, 1.0)',
        // borderColor: 'rgba(204,213,2,1)',
        // borderWidth: 3,
        data: filtered.map((row) => row.minutes),
        yAxisID: 'minutes',
      },
    ],
  }

  const options = {
    scales: {
      xAxes: [{
        gridLines: {
          display: true,
        },
        ticks: {
          display: true,
        },
      }],
      yAxes: [
        {
          id: 'calls',
          ticks: {
            min: 0,
          },
          gridLines: {
            display: false,
          },
          scaleLabel: {
            display: true,
            labelString: __('calls'),
          },
        },
        {
          id: 'minutes',
          ticks: {
            min: 0,
          },
          gridLines: {
            display: false,
          },
          position: 'right',
          scaleLabel: {
            display: true,
            labelString: __('minutes'),
          },
        },
      ],
    },
    legend: {
      display: false,
    },
  }

  return <Bar data={chartData} height={80} options={options} />
}

export default CallsInPhoneGrouppedReport
