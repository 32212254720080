import React from 'react'
import TableRow from './TableRow'
import { __ } from '../../../utils/translate'

const Table = ({ users }) => (
  <div className="table-responsive">
    <table className="table" id="call-data-table" width="100%" cellSpacing="0">
      <thead>
        <tr>
          <th>#</th>
          <th>{__('Email (login)')}</th>
          <th>{__('Roles')}</th>
          <th>{__('Extra permissions')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {users.map((usr) =>
          <TableRow key={usr.userId} user={usr} />)}
      </tbody>
    </table>
  </div>
)


export default Table
