import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = () =>
  axios
    .get('/api/admin/lg-phone-numbers')
    .then(responseMap)


const post = (phoneNumbers = []) =>
  axios
    .post('/api/admin/lg-phone-numbers', {
      phoneNumbers: [...phoneNumbers],
    })
    .then(responseMap)


const destroy = (phoneNumber) =>
  axios
    .delete(`/api/admin/lg-phone-numbers/${phoneNumber}`)
    .then(responseMap)


export default { getAll, post, destroy }
