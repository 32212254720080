import axios from 'axios'
import { responseMap } from '../utils/axios'

const getAll = (customerId) =>
  axios
    .get(`/api/admin/customers/${customerId}/applications`)
    .then(responseMap)

const post = (customerId, name) =>
  axios
    .post(
      `/api/admin/customers/${customerId}/applications`,
      { name }
    )
    .then(responseMap)

const get = (customerId, applicationId) =>
  axios
    .get(
      `/api/admin/customers/${customerId}/applications/${applicationId}`
    )
    .then(responseMap)

const put = (customerId, applicationId, name) =>
  axios
    .put(
      `/api/admin/customers/${customerId}/applications/${applicationId}`,
      { name }
    )
    .then(responseMap)

export default { getAll, post, get, put }
