import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInput, required, onlyNumbers, beginsWithValidPhonePrexix, maxLength, validEmail, renderErrors } from '../../hooks/input'
import employeesService from '../../services/employees'
import { showAlert } from '../../reducers/ui'
import { __ } from '../../utils/translate'
import ClickableParagraph from '../ClickableParagraph'


const EditForm = ({ employee }) => {
  const phoneNumber = useInput('text', employee.phoneNumber, [required(), onlyNumbers(), beginsWithValidPhonePrexix()])
  const identifier = useInput('text', employee.identifier, [required(), maxLength(12)])
  const description = useInput('textarea', employee.description)
  const sid = useInput('text', employee.sid, [maxLength(10)])
  const email = useInput('text', employee.user.email, [required(), validEmail(), maxLength(256)])
  const newPassword = useInput('text', '')

  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const formHasErrors = [
    phoneNumber.hasErrors(),
    identifier.hasErrors(),
    description.hasErrors(),
    sid.hasErrors(),
    email.hasErrors(),
    newPassword.hasErrors(),
  ].some((x) => x)


  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    employeesService
      .put(
        employee.employeeId,
        {
          phoneNumber: phoneNumber.value,
          identifier: identifier.value,
          description: description.value,
          email: email.value,
          sid: sid.value,
          newPassword: newPassword.value,
        }
      )
      .then(() => {
        dispatch(showAlert(__('Employee updated'), 'success'))
        history.push('/employees')
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }

  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword)

  const pwdView = (showNewPassword) ? (
    <div className="form-group">
      <ClickableParagraph onClick={toggleShowNewPassword} icon="caret-up" text={__('New password')} />
      <input className="form-control" {...newPassword.inputProps} />
    </div>
  ) : (
    <ClickableParagraph onClick={toggleShowNewPassword} icon="caret-down" text={__('Change password')} />
  )

  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form-group">
        <label htmlFor="phoneNumber">{__('Phone number')}</label>
        <input className="form-control" name="phoneNumber" {...phoneNumber.inputProps} />
        <small className="form-text text-muted">{__('Phone number must start with 04 or 050')}</small>
        {renderErrors(phoneNumber, isFormSubmitted)}
      </div>
      <div className="form-group">
        <label htmlFor="identifier">{__('Identifier')}</label>
        <input className="form-control" name="identifier" {...identifier.inputProps} />
        <small className="form-text text-muted">{__('Identifier can be any string')}</small>
        {renderErrors(identifier, isFormSubmitted)}
      </div>
      <div className="form-group">
        <label htmlFor="description">{__('Description')}</label>
        <textarea className="form-control" name="description" rows="5" onChange={description.onChange} value={description.value} />
      </div>
      <div className="form-group">
        <label htmlFor="sid">{__('Service identifier')}</label>
        <input className="form-control" name="sid" {...sid.inputProps} />
        {renderErrors(sid, isFormSubmitted)}
      </div>
      <div className="form-group">
        <label htmlFor="email">{__('Email (login)')}</label>
        <input className="form-control" name="email" {...email.inputProps} />
        {renderErrors(email, isFormSubmitted)}
      </div>

      {pwdView}

      <Link to="/employees" className="btn btn-secondary mr-1 mb-3">
        {__('Back')}
      </Link>
      <button type="submit" className="btn btn-primary mb-3" disabled={isFormSubmitted && formHasErrors}>
        {__('Save employee')}
      </button>
    </form>
  )
}

export default EditForm
