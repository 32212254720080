import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import EditRoles from './EditRoles'
import EditPermissions from './EditPermissions'
import usersService from '../../../services/users'
import { useInput } from '../../../hooks/input'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'
import ClickableParagraph from '../../ClickableParagraph'


const EditForm = ({ user, roles, permissions }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const newPassword = useInput('text', '')
  const [showNewPassword, setShowNewPassword] = useState(false)

  const [selectedRoles, setSelectedRoles] = useState(user.roles.map((role) => role.roleId))
  const [selectedPermissions, setSelectedPermissions] = useState(
    user.permissions.map((perm) => perm.permissionId)
  )

  const handleOnSubmit = (e) => {
    e.preventDefault()

    usersService
      .put(
        user.customerId,
        user.userId,
        {
          newPassword: newPassword.value,
          permissions: selectedPermissions,
          roles: selectedRoles,
        }
      )
      .then(() => {
        dispatch(showAlert(__('User updated'), 'success'))
        history.push(`/admin/customers/${user.customerId}/users`)
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }

  const handleRoleOnChange = (event) => {
    const add = !!event.target.checked
    const roleId = +event.target.value

    const newState = (add)
      ? selectedRoles.concat(roleId)
      : selectedRoles.filter((id) => id !== roleId)

    setSelectedRoles(newState)
  }

  const handlePermissionOnChange = (event) => {
    const add = !!event.target.checked
    const permId = +event.target.value

    const newState = (add)
      ? selectedPermissions.concat(permId)
      : selectedPermissions.filter((id) => id !== permId)

    setSelectedPermissions(newState)
  }

  const toggleShowNewPassword = () => {
    newPassword.reset()
    setShowNewPassword(!showNewPassword)
  }

  const pwdView = (showNewPassword) ? (
    <div className="form-group">
      <ClickableParagraph onClick={toggleShowNewPassword} icon="caret-up" text={__('New password')} />
      <input className="form-control" {...newPassword.inputProps} />
    </div>
  ) : (
    <ClickableParagraph onClick={toggleShowNewPassword} icon="caret-down" text={__('Change password')} />
  )


  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form-group">
        <label htmlFor="name">{__('Email (login)')}</label>
        <input className="form-control" type="text" name="email" value={user.email} disabled />
      </div>
      {pwdView}

      <hr />
      <div className="form-row">
        <div className="col">
          <EditRoles
            roles={roles}
            selected={selectedRoles}
            onChange={handleRoleOnChange}
          />
        </div>
        <div className="col">
          <EditPermissions
            permissions={permissions}
            selected={selectedPermissions}
            onChange={handlePermissionOnChange}
          />
        </div>
      </div>

      <hr />
      <Link to={`/admin/customers/${user.customerId}/users`} className="btn btn-secondary mr-1">
        {__('Back')}
      </Link>
      <button type="submit" className="btn btn-primary mr-1">
        {__('Save user')}
      </button>
    </form>
  )
}

export default EditForm
