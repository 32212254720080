import axios from 'axios'
import { responseMap } from '../utils/axios'

const geAllForPeriod = (start, end) =>
  axios
    .get(`/api/shifts?start=${start}&end=${end}`)
    .then(responseMap)

const get = (date) =>
  axios
    .get(`/api/shifts/${date}`)
    .then(responseMap)

const put = (date, hours) =>
  axios
    .put(`/api/shifts/${date}`, { hours })
    .then(responseMap)

export default { geAllForPeriod, get, put }
