import React, { useState } from 'react'
import CallsDateRangePicker from '../CallsDateRangePicker'
import { __ } from '../../../utils/translate'
import { lastMonth } from '../../../utils/date'


const MediaCallsReportForm = ({ onSubmit }) => {
  const [first, last] = lastMonth()
  const [start, setStart] = useState(first)
  const [end, setEnd] = useState(last)

  const handleDateRangeChange = (data) => {
    setStart(data.selection.startDate)
    setEnd(data.selection.endDate)
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
    onSubmit(start, end)
  }

  return (
    <form className="form-inline" onSubmit={handleOnSubmit}>
      <label className="sr-only" htmlFor="end">
        {__('Period')}
      </label>
      <CallsDateRangePicker start={start} end={end} onDateRangeChange={handleDateRangeChange} />

      <button type="submit" className="btn btn-primary mb-2 d-print-none">
        {__('Show report')}
      </button>
    </form>
  )
}

export default MediaCallsReportForm
