import React from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInput } from '../../../hooks/input'
import permissionsService from '../../../services/permissions'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'


const EditForm = ({ permission }) => {
  const description = useInput('textarea', permission.description)
  const history = useHistory()
  const dispatch = useDispatch()

  const handleOnSubmit = (e) => {
    e.preventDefault()

    permissionsService
      .put(
        permission.permissionId,
        description.value
      )
      .then(() => {
        dispatch(showAlert(__('Permission updated'), 'success'))
        history.push({
          pathname: '/admin/authorization',
          hash: '#permissions',
        })
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }


  return (
    <form onSubmit={handleOnSubmit}>
      <div className="form-group">
        <label htmlFor="name">{__('Permission')}</label>
        <input className="form-control" name="name" value={permission.name} disabled />
      </div>
      <div className="form-group">
        <label htmlFor="description">{__('Description')}</label>
        <textarea className="form-control" name="description" onChange={description.onChange} value={description.value} />
      </div>
      <Link to={{ pathname: '/admin/authorization', hash: '#permissions' }} className="btn btn-secondary mr-1">
        {__('Back')}
      </Link>
      <button type="submit" className="btn btn-primary">
        {__('Save permission')}
      </button>
    </form>
  )
}

export default EditForm
