import React, { useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useInput, required, validEmail, renderErrors } from '../../../hooks/input'
import usersService from '../../../services/users'
import { useRoles } from '../../../hooks/roles'
import { showAlert } from '../../../reducers/ui'
import { __ } from '../../../utils/translate'


const Create = () => {
  const [roles] = useRoles()
  const [selected, setSelected] = useState([])

  const email = useInput('text', '', [required(), validEmail()])
  const password = useInput('text', '', [required()])
  const [isFormSubmitted, setFormSubmitted] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()
  const customerId = useParams().custid

  const formHasErrors = [email, password].some((input) => input.hasErrors())


  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    usersService
      .post(
        customerId,
        {
          email: email.value,
          password: password.value,
          roles: selected,
        }
      )
      .then(() => {
        dispatch(showAlert(__('User created'), 'success'))
        history.push(`/admin/customers/${customerId}/users`)
      })
      .catch((error) =>
        error.response
          && error.response.data
          && error.response.data.errors
          && error.response.data.errors.map((msg) =>
            dispatch(showAlert(msg, 'danger'))))
  }


  const handleRoleOnChange = (event) => {
    const add = !!event.target.checked
    const roleId = +event.target.value

    const newState = (add)
      ? selected.concat(roleId)
      : selected.filter((id) => id !== roleId)

    setSelected(newState)
  }

  return (
    <>
      <h3>{__('Create user')}</h3>

      <hr />

      <form onSubmit={handleOnSubmit}>
        <div className="form-group">
          <label htmlFor="email">{__('Email (login)')}</label>
          <input className="form-control" name="email" {...email.inputProps} />
          <small className="form-text text-muted">
            {__('Email address is used as username when user login')}
          </small>
          {renderErrors(email, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="password">{__('Password')}</label>
          <input className="form-control" name="password" {...password.inputProps} />
          <small className="form-text text-muted">
            {__('Set some dummy password and encourage user to change it')}
          </small>
          {renderErrors(password, isFormSubmitted)}
        </div>
        <div className="form-group mb-3">
          <label htmlFor="roles">{__('Roles')}</label>
          {roles.map((role) => (
            <div key={role.roleId} className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                value={role.roleId}
                checked={selected.includes(role.roleId)}
                onChange={handleRoleOnChange}
              />
              <label className="form-check-label" htmlFor="role">{role.name}</label>
            </div>
          ))}
        </div>
        <Link to={`/admin/customers/${customerId}/users`} className="btn btn-secondary mr-1">
          {__('Back')}
        </Link>
        <button type="submit" className="btn btn-primary" disabled={isFormSubmitted && formHasErrors}>
          {__('Save user')}
        </button>
      </form>
    </>
  )
}

export default Create
