import { useState, useEffect } from 'react'
import rolesService from '../services/roles'

export const useRole = (id) => {
  const [role, setRole] = useState(null)

  useEffect(() => {
    rolesService
      .get(id)
      .then((rol) => setRole(rol))
  }, [id])

  return [role, setRole]
}

export const useRoles = () => {
  const [roles, setRoles] = useState([])

  useEffect(() => {
    rolesService
      .getAll()
      .then((rols) => setRoles(rols))
  }, [])

  return [roles, setRoles]
}
