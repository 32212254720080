import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { __ } from '../../../utils/translate'
import { renderErrors, required, useInput } from '../../../hooks/input'
import { onlyNumbers, splitTextToLines } from '../../../utils/strings'
import Modal from '../../Modal'
import lgPhoneNumbersService from '../../../services/lg-phone-numbers'
import { showAlert } from '../../../reducers/ui'

const Create = () => {
  const phoneNumbers = useInput('textarea', '', [required()])

  const [cleanedNumbers, setCleanedNumbers] = useState([]);
  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const formHasErrors = !!phoneNumbers.errors.length

  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    const inputPhones = splitTextToLines(phoneNumbers.value).map(onlyNumbers).filter((n) => !!n)

    if (inputPhones.length < 1) {
      phoneNumbers.errors.push(required()[1])
      return
    }

    setCleanedNumbers(inputPhones)
    setIsModalOpen(true)
  }

  const handleOnConfirm = () => {
    lgPhoneNumbersService
      .post(cleanedNumbers)
      .then((resp) => {
        const saved = resp.phoneNumbers.length
        const skipped = resp.skippedPhoneNumbers.length

        if (saved) {
          dispatch(showAlert(`${__('LG SIM cards saved')}: ${saved}`, 'success'))
        }

        if (skipped) {
          dispatch(showAlert(`${__('Some SIM cards were not saved')}: ${skipped}`, 'warning'))
        }

        history.push('/admin/lg-phone-numbers')
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
      .finally(() => {
        setIsModalOpen(false)
        setCleanedNumbers([])
      })
  }

  return (
    <>
      <h3>{__('Add LG SIM card')}</h3>
      <hr />

      <form onSubmit={handleOnSubmit}>
        <div className="form-group">
          <label htmlFor="phone-numbers">{__('Phone number')}</label>
          <textarea className="form-control" rows="10" name="phone-numbers" {...phoneNumbers.inputProps} />
          <small className="form-text text-muted">{__('You can add multiple phone numbers. One phone number per line.')}</small>
          {renderErrors(phoneNumbers, isFormSubmitted)}
        </div>

        <Link to="/admin/lg-phone-numbers" className="btn btn-secondary mr-1">
          {__('Cancel')}
        </Link>
        <button type="submit" className="btn btn-primary" disabled={isFormSubmitted && formHasErrors}>
          {__('Save')}
        </button>
      </form>
      <Modal
        isOpen={isModalOpen}
        title={__('Save new LG SIM cards')}
        onClose={() => setIsModalOpen(false)}
        closeText={__('Cancel')}
        onConfirm={handleOnConfirm}
      >
        <p>{__('Number of new SIM cards')}: {cleanedNumbers.length}</p>
        <p>{__('Are you sure you want to save these new LG SIM cards?')}</p>
      </Modal>
    </>
  )
}

export default Create
