import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import applicationNumbersService from '../../../services/application-numbers'
import { __ } from '../../../utils/translate'
import { showAlert } from '../../../reducers/ui'


const CreateRangeForm = ({ customerId, applicationId }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()

    const cNumberPrefix = e.target.cNumberPrefix.value
    const firstCNumberSuffix = e.target.firstCNumberSuffix.value
    const lastCNumberSuffix = e.target.lastCNumberSuffix.value

    if (cNumberPrefix && firstCNumberSuffix && lastCNumberSuffix) {
      applicationNumbersService
        .postRange(
          customerId,
          applicationId,
          { cNumberPrefix, firstCNumberSuffix, lastCNumberSuffix }
        )
        .then(() => {
          dispatch(showAlert(__('New connected numbers created'), 'success'))
          history.push(`/admin/customers/${customerId}/applications/${applicationId}/numbers`)
        })
        .catch((error) =>
          error.response
          && error.response.data
          && error.response.data.errors
          && error.response.data.errors.map((msg) =>
            dispatch(showAlert(msg, 'danger'))))
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          <label htmlFor="cNumberPrefix">{__('CNumber prefix')}</label>
          <input type="text" className="form-control" name="cNumberPrefix" placeholder="049100" />
        </div>
        <div className="col-md-6">
          <label htmlFor="firstCNumberSuffix">{__('First CNumber suffix')}</label>
          <input type="text" className="form-control" name="firstCNumberSuffix" placeholder="0000" />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 offset-md-6">
          <label htmlFor="lastCNumberSuffix">{__('Last CNumber suffix')}</label>
          <input type="text" className="form-control" name="lastCNumberSuffix" placeholder="9999" />
        </div>
      </div>
      <Link to={`/admin/customers/${customerId}/applications/${applicationId}/numbers`} className="btn btn-secondary mr-1">
        {__('Back')}
      </Link>
      <button type="submit" className="btn btn-primary">{__('Save connected numbers')}</button>
    </form>
  )
}

export default CreateRangeForm
