import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Modal from './Modal'
import { useInput } from '../hooks/input'
import LoginService from '../services/login'
import { userLogin } from '../reducers/authentication'
import { bodyClassAdd, bodyClassRemove } from '../utils/dom'
import { __, getLang } from '../utils/translate'
import './Login.scss'

const Login = () => {
  const [showLoginFailed, setShowLoginFailed] = useState(false)

  useEffect(() => {
    bodyClassAdd('bg-dark')
    return () => bodyClassRemove('bg-dark')
  }, [])

  const email = useInput('email', '')
  const password = useInput('password', '')

  const dispatch = useDispatch()

  const handleOnSubmit = (event) => {
    event.preventDefault()

    if (!email.value || !password.value) {
      return
    }

    LoginService
      .doLogin(email.value, password.value)
      .then((user) => {
        email.reset()
        password.reset()
        dispatch(userLogin(user))

        if (user.lang && user.lang !== getLang()) {
          // Reload whole page to load the correct translations
          window.location = '/'
        }
      })
      .catch(() => {
        password.reset()
        setShowLoginFailed(true)
      })
  }

  const header = (new URL(window.location)).hostname

  return (
    <div className="container">
      <div className="card card-login mx-auto mt-5">
        <div className="card-header">
          <div id="login-header">
            <img src="/favicon/favicon-16x16.png" alt="" />
            {header}
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleOnSubmit}>
            <div className="form-group">
              <div className="form-label-group">
                <input className="form-control" type={email.type} value={email.value} onChange={email.onChange} />
                <label htmlFor="inputEmail">{__('Email address')}</label>
              </div>
            </div>
            <div className="form-group">
              <div className="form-label-group">
                <input className="form-control" type={password.type} value={password.value} onChange={password.onChange} />
                <label htmlFor="inputPassword">{__('Password')}</label>
              </div>
            </div>
            <button type="submit" className="btn btn-primary btn-block">{__('Login')}</button>
          </form>
        </div>
      </div>
      {showLoginFailed && (
        <Modal
          isOpen
          onClose={() => setShowLoginFailed(false)}
          title={__('Login failed')}
          confirmTextBtnType="hidden"
        >
          <p className="text-danger">{__('Invalid email address or password')}</p>
        </Modal>
      )}
      <div className="mt-3 mx-auto text-center text-white">
        <span className="mr-2">{__('Language')}:</span>
        <LangLink code="fi" flag="fi" text={__('finnish')} className="ml-2" />
        <LangLink code="en" flag="gb" text={__('english')} className="ml-2" />
      </div>
    </div>
  )
}

const LangLink = ({ code, flag, text, className = '' }) => (
  <span className={className}>
    <span className={`fi fis fi-${flag} mr-1`} />
    {code === getLang()
      ? <span className="text-muted">{text}</span>
      : <a href={`/?lang=${code}`} disabled>{text}</a>}
  </span>
)


export default Login
