import React, { useEffect, useState } from 'react'
import { Line } from 'react-chartjs-2'
import { useDispatch } from 'react-redux'
import StatisticCard from '../StatisticCard'
import CallsInDataTable from './CallsInDataTable'
import callsService from '../../../services/calls'
import { __ } from '../../../utils/translate'
import { showAlert } from '../../../reducers/ui'

const CallsInReport = ({ phoneNumber, start, end }) => {
  const [report, setReport] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    callsService
      .getInCalls(phoneNumber, start, end)
      .then(setReport)
      .catch((error) => {
        if (error.response?.status === 419) {
          dispatch(showAlert(
            __('Requested report too large. Try shorter period (usually 1 day), show report groupped by date or select only single number.'),
            'danger'
          ))
        }
      })
  }, [phoneNumber, start, end, dispatch])

  if (report === null) {
    return <></>
  }

  return (
    <>
      <div className="row d-print-none">
        <div className="col">
          <span className="text-muted">{__('Minutes')}</span>
          <CNumberMinutesLineChart data={report.calls} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="clock" text={__('Total call duration')} value={`${report.totals.minutesHuman} min`} />
        </div>
        <div className="col-xl-6 mb-3">
          <StatisticCard icon="phone-square" text={__('Total calls')} value={report.totals.calls} />
        </div>
      </div>
      <div className="row">
        <div className="col-xl-12">
          <CallsInDataTable calls={report.calls} />
        </div>
      </div>
    </>
  )
}


const CNumberMinutesLineChart = ({ data }) => {
  if (data.length > 100) {
    // Don't show chart with more than 100 "categories".
    // Even length > 10 won't look so good.
    return <></>
  }

  // https://learnui.design/tools/data-color-picker.html
  const chartColours = [
    '#00485c',
    '#006c6c',
    '#008d53',
    '#88a41a',
    '#ffa600',
  ]

  // Group by cNumber
  const buckets = data.reduce((groupped, row) => {
    const result = { ...groupped }
    if (!(row.cNumber in result)) {
      result[row.cNumber] = []
    }
    result[row.cNumber].push(row)
    return result
  }, {})

  // Calculate total minutes for each cNumber and sort them
  const totals = Object.keys(buckets).map((cNumber) => {
    const sum = buckets[cNumber].reduce((carry, row) => carry + row.minutes, 0)
    return [cNumber, sum]
  }).sort((a, b) => b[1] - a[1])
  // Take top cNumbers
  const top = totals.slice(0, chartColours.length)
  // And assign colors to them
  const topColors = top.reduce((colors, [cNumber], i) => {
    const result = { ...colors }
    result[cNumber] = chartColours[i]
    return result
  }, {})
  // Assume that data is ordered by date and cNumber
  const xLabels = data.reduce((labels, row) => {
    if (!labels.includes(row.date)) {
      labels.push(row.date)
    }
    return labels
  }, [])

  const chartData = {
    labels: xLabels,
    datasets: Object.values(buckets).map((rows) => {
      const { cNumber } = rows[0]
      const color = (cNumber in topColors) ? topColors[cNumber] : '#69'

      return {
        label: cNumber,
        data: rows.map((row) => row.minutes),
        fill: false, // no background below line
        // https://www.chartjs.org/docs/2.9.4/charts/line.html
        backgroundColor: color,
        borderColor: color,
        hoverBackgroundColor: color,
        hoverBorderColor: color,
        pointBackgroundColor: color,
        pointBorderColor: color,
        pointHoverBackgroundColor: color,
        pointHoverBorderColor: color,
      }
    }),
  }

  const options = {
    scales: {
      xAxes: [{
        time: {
          unit: 'date',
        },
        gridLines: {
          display: false,
        },
        ticks: {
          display: false,
        },
      }],
    },
    legend: {
      display: true,
    },
  }

  /**
   * For some reason, the resulting chart has colors all mixed
   * up. Don't know why. Even the legends at the top are colored
   * with right colors, but the lines have wrong colors...
   */
  // console.log(chartData, options)

  return <Line data={chartData} height={80} options={options} />
}

export default CallsInReport
