import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { __, getLang } from '../utils/translate'
import translationsService from '../services/translations'
import { showAlert } from '../reducers/ui'

const ChangeLanguage = () => {
  // Should fetch these from backend...
  const languages = [
    { lang: 'en', name: __('english'), flag: 'gb' },
    { lang: 'fi', name: __('finnish'), flag: 'fi' },
  ]

  const dispatch = useDispatch()

  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [checked, setChecked] = useState(getLang())

  const handleSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    translationsService
      .changeLanguage(checked)
      .then((resp) => {
        if (resp.lang !== getLang()) {
          dispatch(showAlert(__('Language changed'), 'info'))
          // Reload whole page to load the correct translations
          window.location.reload()
        }
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
      .finally(() => setFormSubmitted(false))
  }

  return (
    <>
      <h3>{__('Change language')}</h3>
      <hr />
      <div className="row">
        <div className="col">
          <form className="mb-3" onSubmit={handleSubmit}>
            {languages.map(({ lang, name, flag }) => (
              <div key={lang} className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="lang"
                  value={lang}
                  checked={checked === lang}
                  onChange={() => setChecked(lang)}
                />
                <label className="form-check-label" htmlFor="name">
                  <span className={`fi fi-${flag} mr-1`} />
                  {name}
                </label>
              </div>
            ))}
            <button type="submit" className="btn btn-primary mt-3" disabled={isFormSubmitted || checked === getLang()}>
              {__('Change language')}
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default ChangeLanguage
