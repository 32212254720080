import React, { useState } from 'react'
import { useHistory, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useInput, required, onlyNumbers, beginsWithValidPhonePrexix, maxLength, renderErrors, validEmail } from '../../hooks/input'
import employeesService from '../../services/employees'
import { showAlert } from '../../reducers/ui'
import { __ } from '../../utils/translate'
import { randomString } from '../../utils/strings'


const Create = () => {
  const customer = useSelector((state) => state.authentication.customer)
  const [, domain] = customer.email.split('@')

  const phoneNumber = useInput('text', '', [required(), onlyNumbers(), beginsWithValidPhonePrexix()])
  const identifier = useInput('text', '', [required(), maxLength(12)])
  const description = useInput('textarea')
  const sid = useInput('text', '', [maxLength(10)])
  const email = useInput('text', '', [required(), validEmail()])
  const password = useInput('text', '', [required()])

  const [isFormSubmitted, setFormSubmitted] = useState(false)
  const [emailIsModified, setEmailIsModified] = useState(false)

  const history = useHistory()
  const dispatch = useDispatch()

  const formHasErrors = [
    phoneNumber.hasErrors(),
    identifier.hasErrors(),
    description.hasErrors(),
    sid.hasErrors(),
    email.hasErrors(),
    password.hasErrors(),
  ].some((x) => x)


  const handleOnSubmit = (e) => {
    e.preventDefault()
    setFormSubmitted(true)

    if (formHasErrors) {
      return
    }

    employeesService
      .post(
        {
          phoneNumber: phoneNumber.value,
          identifier: identifier.value,
          description: description.value,
          sid: sid.value,
          email: email.value,
          password: password.value,
        }
      )
      .then(() => {
        dispatch(showAlert(__('Employee created'), 'success'))
        history.push('/employees')
      })
      .catch((error) =>
        error.response
        && error.response.data
        && error.response.data.errors
        && error.response.data.errors.map((msg) =>
          dispatch(showAlert(msg, 'danger'))))
  }

  // Bind user identifier and email together, when email has not yet been modified
  const handleIdentifierOnChange = (event) => {
    identifier.onChange(event)

    if (!emailIsModified) {
      email.setValue(`${event.target.value.replace(/[^0-9A-Za-z\-_]/, '')}@${domain}`)
    }
  }

  const handleEmailOnChange = (event) => {
    setEmailIsModified(true)
    email.onChange(event)
  }

  const handlePwGenerateClick = () => password.setValue(randomString(15))

  return (
    <>
      <h3>{__('Create employee')}</h3>

      <hr />

      <form onSubmit={handleOnSubmit}>
        <div className="form-group">
          <label htmlFor="phoneNumber">{__('Phone number')}</label>
          <input className="form-control" name="phoneNumber" {...phoneNumber.inputProps} />
          <small className="form-text text-muted">{__('Phone number must start with 04 or 050')}</small>
          {renderErrors(phoneNumber, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="identifier">{__('Identifier')}</label>
          <input
            className="form-control"
            name="identifier"
            type={identifier.type}
            value={identifier.value}
            onChange={handleIdentifierOnChange}
            onBlur={identifier.onBlur}
          />
          <small className="form-text text-muted">{__('Identifier can be any string')}</small>
          {renderErrors(identifier, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="description">{__('Description')}</label>
          <textarea className="form-control" name="description" rows="5" onChange={description.onChange} value={description.value} />
        </div>
        <div className="form-group">
          <label htmlFor="sid">{__('Service identifier')}</label>
          <input className="form-control" name="sid" {...sid.inputProps} />
          {renderErrors(sid, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="email">{__('Email (login)')}</label>
          <input
            className="form-control"
            name="email"
            type={email.type}
            value={email.value}
            onChange={handleEmailOnChange}
            onBlur={email.onBlur}
          />
          {renderErrors(email, isFormSubmitted)}
        </div>
        <div className="form-group">
          <label htmlFor="password">{__('Password')}</label>
          <div className="input-group">
            <input className="form-control" name="password" {...password.inputProps} />
            <div className="input-group-append">
              <button type="button" className="input-group-text" onClick={handlePwGenerateClick} title={__('Generate random password')}>
                <i className="fa fa-random" />
              </button>
            </div>
          </div>
          {renderErrors(password, isFormSubmitted)}
        </div>

        <Link to="/employees" className="btn btn-secondary mr-1 mb-3">
          {__('Cancel')}
        </Link>
        <button type="submit" className="btn btn-primary mb-3" disabled={isFormSubmitted && formHasErrors}>
          {__('Save employee')}
        </button>
      </form>
    </>
  )
}

export default Create
