import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import applicationNumbersService from '../../../services/application-numbers'
import { __ } from '../../../utils/translate'
import { showAlert } from '../../../reducers/ui'


const CreateListForm = ({ customerId, applicationId }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const handleSubmit = (e) => {
    e.preventDefault()

    const numberList = e.target.numberList.value

    if (numberList) {
      applicationNumbersService
        .postList(
          customerId,
          applicationId,
          numberList
        )
        .then(() => {
          dispatch(showAlert(__('New connected numbers created'), 'success'))
          history.push(`/admin/customers/${customerId}/applications/${applicationId}/numbers`)
        })
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="numberList">
          {__('CNumbers list')}
        </label>
        <textarea className="form-control" name="numberList" rows="15" />
        <small className="form-text text-muted">
          {__('List of CNumbers each number on separate line')}
        </small>
      </div>
      <Link to={`/admin/customers/${customerId}/applications/${applicationId}/numbers`} className="btn btn-secondary mr-1">
        {__('Back')}
      </Link>
      <button type="submit" className="btn btn-primary">
        {__('Save connected numbers')}
      </button>
    </form>
  )
}

export default CreateListForm
