import React from 'react'
import { Link } from 'react-router-dom'
import { __ } from '../../../utils/translate'
import { userHasPermission } from '../../../utils/authorization'

const TableRow = ({ user }) => (
  <>
    <tr>
      <td>{user.userId}</td>
      <td>{user.email}</td>
      <td>{user.roles.map((role) => role.name).join(', ')}</td>
      <td>
        {user.permissions.length > 0 && (
          <span className="badge badge-pill badge-secondary">{user.permissions.length}</span>
        )}
      </td>
      <td>
        {userHasPermission('update-users') && (
          <Link
            className="btn btn-light btn-sm mr-1"
            role="button"
            to={`/admin/customers/${user.customerId}/users/${user.userId}/edit`}
            title={__('edit')}
          >
            <i className="fas fa-pen" />
          </Link>
        )}
      </td>
    </tr>
  </>
)

export default TableRow
